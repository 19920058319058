import React, { useCallback } from 'react';
import type { FormikProps } from 'formik';
import styled from 'styled-components';

import { Row, Alert, Button } from 'components/bootstrap';
import useUserDateTime from 'hooks/useUserDateTime';
import type { DataLakeSearchType } from 'data-lake/preview/Types';
import { onInitializingTimerange } from 'views/components/TimerangeForForm';
import { WIDGET_DEFAULTS } from 'data-lake/preview/Constants';
import type Search from 'views/logic/search/Search';
import type { FormValues } from 'data-lake/preview/SearchBar';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledAlert = styled(Alert)`
  margin-top: 0;
`;

type Props = {
  expiredSearch: Search;
  searchBarFormRef: React.RefObject<FormikProps<FormValues>>;
};

const ExpiredSearchInfo = ({ expiredSearch, searchBarFormRef }: Props) => {
  const { formatTime } = useUserDateTime();
  const restartExpiredSearch = useCallback(() => {
    const searchType = expiredSearch.queries.first().searchTypes[0] as DataLakeSearchType;
    searchBarFormRef.current?.setValues({
      stream: searchType.streams[0],
      timerange: onInitializingTimerange(searchType.timerange, formatTime),
      fields: {
        fieldFilters: searchType.field_filters ?? WIDGET_DEFAULTS.fieldFilters,
        operator: searchType.filters_operator ?? WIDGET_DEFAULTS.fieldsOperator,
      },
    });
  }, [expiredSearch.queries, formatTime, searchBarFormRef]);

  return (
    <Row>
      <StyledAlert bsStyle="default">
        <Container>
          <span>
            Your previous search has expired. Search results are stored for 24 hours and have now been cleared. You can
            reapply the previous filters or start a completely new search.
          </span>
          <Button bsSize="xsmall" bsStyle="info" onClick={restartExpiredSearch}>
            Reapply filters
          </Button>
        </Container>
      </StyledAlert>
    </Row>
  );
};

export default ExpiredSearchInfo;
