import * as Immutable from 'immutable';

import WidgetConfig from 'views/logic/widgets/WidgetConfig';
import type { After } from 'logview/types';

type InternalState = {
  after: After;
  fields: Immutable.OrderedSet<string>;
  size: number;
  sort: 'ASC' | 'DESC';
  tieBreaker: string;
};

export type LogViewWidgetConfigJSON = {
  after: After;
  fields: Array<string>;
  size: InternalState['size'];
  sort: InternalState['sort'];
  tie_breaker: InternalState['tieBreaker'];
};

export default class LogViewWidgetConfig extends WidgetConfig {
  _value: InternalState;

  static defaultFields: InternalState['fields'] = Immutable.OrderedSet(['timestamp', 'source', 'message']);

  static defaultSortDirection: InternalState['sort'] = 'DESC';

  static defaultSize: InternalState['size'] = 100;

  constructor(
    after: InternalState['after'],
    fields: InternalState['fields'],
    size: InternalState['size'],
    sort: InternalState['sort'],
    tieBreaker: InternalState['tieBreaker'],
  ) {
    super();

    this._value = { after, fields, size, sort, tieBreaker };
  }

  get after() {
    return this._value.after;
  }

  get fields() {
    return this._value.fields;
  }

  get size() {
    return this._value.size;
  }

  get sort() {
    return this._value.sort;
  }

  get tieBreaker() {
    return this._value.tieBreaker;
  }

  toBuilder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Immutable.Map(this._value));
  }

  toJSON() {
    const { after, fields = Immutable.OrderedSet(), size, sort, tieBreaker } = this._value;

    return {
      after,
      fields: fields.toArray(),
      size,
      sort,
      tie_breaker: tieBreaker,
    };
  }

  static builder(): Builder {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder().fields(Immutable.OrderedSet());
  }

  static fromJSON(value: LogViewWidgetConfigJSON) {
    const { after, fields, size, sort, tie_breaker } = value;

    return new LogViewWidgetConfig(after, Immutable.OrderedSet(fields), size, sort, tie_breaker);
  }

  static createDefault() {
    return new LogViewWidgetConfig(
      undefined,
      LogViewWidgetConfig.defaultFields,
      LogViewWidgetConfig.defaultSize,
      LogViewWidgetConfig.defaultSortDirection,
      undefined,
    );
  }
}

type BuilderState = Immutable.Map<string, any>;

class Builder {
  value: BuilderState;

  constructor(value: BuilderState = Immutable.Map()) {
    this.value = value;
  }

  after(value: InternalState['after']) {
    return new Builder(this.value.set('after', value));
  }

  fields(value: InternalState['fields']) {
    return new Builder(this.value.set('fields', value));
  }

  size(value: InternalState['size']) {
    return new Builder(this.value.set('size', value));
  }

  sort(value: InternalState['sort']) {
    return new Builder(this.value.set('sort', value));
  }

  tieBreaker(value: InternalState['tieBreaker']) {
    return new Builder(this.value.set('tieBreaker', value));
  }

  build() {
    const { after, fields, size, sort, tieBreaker } = this.value.toObject();

    return new LogViewWidgetConfig(after, fields, size, sort, tieBreaker);
  }
}
