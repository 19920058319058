import { useMemo, useContext } from 'react';

import { useGetPacks } from 'illuminate/hooks/usePacksAPI';
import { BundleContext } from 'illuminate/context/BundleContext';

const useThreatRelatedIlluminatePacks = () => {
  const { bundleVersion } = useContext(BundleContext);
  const { packs = [], isInitialLoading } = useGetPacks(
    { page: 1, perPage: 100000, query: 'tags:attack', includeDeps: true },
    bundleVersion,
  );

  const notEnabledPacks = useMemo(() => packs.filter(({ enabled }) => !enabled), [packs]);

  return {
    isInitialLoading,
    allPacks: packs,
    notEnabledPacks,
  };
};

export default useThreatRelatedIlluminatePacks;
