import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import IconButton from 'components/common/IconButton';
import { ButtonToolbar } from 'components/bootstrap';
import { ClipboardIconButton } from 'components/common';

const StyledLogViewRow = styled.div<{ $highlight: boolean }>(
  ({ theme, $highlight }) => css`
    display: flex;
    min-width: fit-content;
    font-family: ${theme.fonts.family.monospace};
    padding: 3px 5px;
    border-bottom: 1px solid ${theme.colors.table.row.divider};
    position: relative;
    gap: ${theme.spacings.xxs};
    /* stylelint-disable selector-max-empty-lines, indentation */
    ${$highlight &&
    css`
      background-color: ${theme.colors.table.row.backgroundHover};
      color: ${theme.utils.contrastingColor(theme.colors.table.row.backgroundHover)};
    `}
    /* stylelint-enable selector-max-empty-lines, indentation */

  &:hover {
      background-color: ${theme.colors.table.row.backgroundHover};
      color: ${theme.utils.contrastingColor(theme.colors.table.row.backgroundHover)};
    }
  `,
);

const StyledButtonToolbar = styled(ButtonToolbar)(
  ({ theme }) => css`
    position: absolute;
    right: 1px;
    top: 0;
    max-height: 100%;
    background-color: ${theme.colors.table.row.backgroundHover};
  `,
);

type Props = {
  children: React.ReactNode;
  highlight: boolean;
  message: { [fieldName: string]: unknown };
  onShowDetails?: () => void;
};

const LogViewRow = ({ children, onShowDetails = undefined, highlight, message }: Props) => {
  const [showActions, setShowActions] = useState(false);

  return (
    <StyledLogViewRow
      $highlight={highlight}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {children}
      {showActions && (
        <StyledButtonToolbar>
          <ClipboardIconButton buttonTitle="Copy message" text={JSON.stringify(message, null, 2)} />
          <IconButton title="Open message details" name="open_in_full" onClick={onShowDetails} />
        </StyledButtonToolbar>
      )}
    </StyledLogViewRow>
  );
};

export default LogViewRow;
