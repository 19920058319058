import { selectSearchExecutionResult } from 'views/logic/slices/searchExecutionSelectors';
import useAppSelector from 'stores/useAppSelector';
import type { SearchExecution } from 'views/types';

const useDLPreviewSelector = <R>(fn: (state: { searchExecution: SearchExecution }) => R) =>
  useAppSelector<R, { searchExecution: SearchExecution }>(fn);

const useSearchResult = () => useDLPreviewSelector(selectSearchExecutionResult);

export default useSearchResult;
