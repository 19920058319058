import styled, { css, useTheme } from 'styled-components';
import * as React from 'react';

import { RowContentStyles } from 'components/bootstrap/Row';
import { Link } from 'components/common/router';
import { COLOR_SCHEME_LIGHT } from 'theme/constants';

const darkModeImage = (
  <svg width="211" height="109" viewBox="0 0 211 109" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M201.6 0C206.471 0 210.42 3.94884 210.42 8.82C210.42 13.6912 206.471 17.64 201.6 17.64H151.2C156.071 17.64 160.02 21.5888 160.02 26.46C160.02 31.3312 156.071 35.28 151.2 35.28H178.92C183.791 35.28 187.74 39.2288 187.74 44.1C187.74 48.9712 183.791 52.92 178.92 52.92H166.101C159.96 52.92 154.98 56.8688 154.98 61.74C154.98 64.987 157.5 67.9279 162.54 70.56C167.411 70.56 171.36 74.5088 171.36 79.38C171.36 84.2512 167.411 88.2 162.54 88.2H57.96C53.0888 88.2 49.14 84.2512 49.14 79.38C49.14 74.5088 53.0888 70.56 57.96 70.56H8.82C3.94884 70.56 0 66.6112 0 61.74C0 56.8688 3.94884 52.92 8.82 52.92H59.22C64.0912 52.92 68.04 48.9712 68.04 44.1C68.04 39.2288 64.0912 35.28 59.22 35.28H27.72C22.8488 35.28 18.9 31.3312 18.9 26.46C18.9 21.5888 22.8488 17.64 27.72 17.64H78.12C73.2488 17.64 69.3 13.6912 69.3 8.82C69.3 3.94884 73.2488 0 78.12 0H201.6ZM201.6 35.28C206.471 35.28 210.42 39.2288 210.42 44.1C210.42 48.9712 206.471 52.92 201.6 52.92C196.729 52.92 192.78 48.9712 192.78 44.1C192.78 39.2288 196.729 35.28 201.6 35.28Z"
      fill="#394261"
    />
    <path
      d="M92.6099 85.68C115.922 85.68 134.82 66.7825 134.82 43.47C134.82 20.1581 115.922 1.26001 92.6099 1.26001C69.2974 1.26001 50.3999 20.1581 50.3999 43.47C50.3999 66.7825 69.2974 85.68 92.6099 85.68Z"
      fill="#394261"
      stroke="#898FA2"
      strokeWidth="2.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.8463 76.2225C88.0626 76.632 90.3168 76.8436 92.61 76.86C111.051 76.86 126 61.9114 126 43.47C126 25.0293 111.051 10.08 92.61 10.08C87.8724 10.08 83.3666 11.0665 79.2842 12.8452C72.1904 15.9364 66.3788 21.4199 62.8689 28.2768C60.536 32.8342 59.22 37.9985 59.22 43.47C59.22 48.4344 60.3034 53.1456 62.2466 57.3804C63.634 60.4044 65.4596 63.184 67.6418 65.641"
      fill="#252D47"
    />
    <path
      d="M85.8463 76.2225C88.0626 76.632 90.3168 76.8436 92.61 76.86C111.051 76.86 126 61.9114 126 43.47C126 25.0293 111.051 10.08 92.61 10.08C87.8724 10.08 83.3666 11.0665 79.2842 12.8452C72.1904 15.9364 66.3788 21.4199 62.8689 28.2768C60.536 32.8342 59.22 37.9985 59.22 43.47C59.22 48.4344 60.3034 53.1456 62.2466 57.3804C63.634 60.4044 65.4596 63.184 67.6418 65.641"
      stroke="#898FA2"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M71.5642 69.3945C74.2707 71.5945 77.3287 73.3786 80.6413 74.6512"
      stroke="#898FA2"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path d="M127.26 76.86L134.82 84.42" stroke="#898FA2" strokeWidth="2.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.598 83.1978C131.214 85.583 131.214 89.4499 133.598 91.8338L147.566 105.802C149.95 108.186 153.817 108.186 156.202 105.802C158.587 103.417 158.587 99.5501 156.202 97.1661L142.235 83.1978C139.85 80.8139 135.983 80.8139 133.598 83.1978Z"
      fill="#394261"
      stroke="#898FA2"
      strokeWidth="2.5"
    />
    <path d="M139.86 85.68L153.72 99.54" stroke="#394261" strokeWidth="2.5" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.42 28.98C84.42 43.5935 96.2665 55.44 110.88 55.44C113.75 55.44 116.515 54.9826 119.103 54.1372C114.878 64.6216 104.609 72.0229 92.61 72.0229C76.8411 72.0229 64.0576 59.2389 64.0576 43.47C64.0576 29.1811 74.5542 17.3437 88.2567 15.2474C85.8224 19.2519 84.42 23.9522 84.42 28.98Z"
      fill="#384265"
    />
    <path
      d="M93.24 20.16C91.6361 20.16 90.0674 20.3179 88.5516 20.6189M83.9727 22.0199C75.3531 25.642 69.3 34.1641 69.3 44.1"
      stroke="#6F768F"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M160.242 43.8194H149.94M168.21 34.02H146.568M177.03 34.02H174.232"
      stroke="#6F768F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.8416 71.539H36.5401M40.9501 60.48H19.3086M11.9701 60.48H6.65137"
      stroke="#6F768F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const lightModeImage = (
  <svg width="211" height="109" viewBox="0 0 211 109" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M201.6 0C206.471 0 210.42 3.94884 210.42 8.82C210.42 13.6912 206.471 17.64 201.6 17.64H151.2C156.071 17.64 160.02 21.5888 160.02 26.46C160.02 31.3312 156.071 35.28 151.2 35.28H178.92C183.791 35.28 187.74 39.2288 187.74 44.1C187.74 48.9712 183.791 52.92 178.92 52.92H166.101C159.96 52.92 154.98 56.8688 154.98 61.74C154.98 64.987 157.5 67.9279 162.54 70.56C167.411 70.56 171.36 74.5088 171.36 79.38C171.36 84.2512 167.411 88.2 162.54 88.2H57.96C53.0888 88.2 49.14 84.2512 49.14 79.38C49.14 74.5088 53.0888 70.56 57.96 70.56H8.82C3.94884 70.56 0 66.6112 0 61.74C0 56.8688 3.94884 52.92 8.82 52.92H59.22C64.0912 52.92 68.04 48.9712 68.04 44.1C68.04 39.2288 64.0912 35.28 59.22 35.28H27.72C22.8488 35.28 18.9 31.3312 18.9 26.46C18.9 21.5888 22.8488 17.64 27.72 17.64H78.12C73.2488 17.64 69.3 13.6912 69.3 8.82C69.3 3.94884 73.2488 0 78.12 0H201.6ZM201.6 35.28C206.471 35.28 210.42 39.2288 210.42 44.1C210.42 48.9712 206.471 52.92 201.6 52.92C196.729 52.92 192.78 48.9712 192.78 44.1C192.78 39.2288 196.729 35.28 201.6 35.28Z"
      fill="#F6F7FC"
    />
    <path
      d="M92.6099 85.68C115.922 85.68 134.82 66.7825 134.82 43.47C134.82 20.1581 115.922 1.26001 92.6099 1.26001C69.2974 1.26001 50.3999 20.1581 50.3999 43.47C50.3999 66.7825 69.2974 85.68 92.6099 85.68Z"
      fill="#E1E4ED"
      stroke="#394261"
      strokeWidth="2.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.8463 76.2225C88.0626 76.632 90.3168 76.8436 92.61 76.86C111.051 76.86 126 61.9114 126 43.47C126 25.0293 111.051 10.08 92.61 10.08C87.8724 10.08 83.3666 11.0665 79.2842 12.8452C72.1904 15.9364 66.3788 21.4199 62.8689 28.2768C60.536 32.8342 59.22 37.9985 59.22 43.47C59.22 48.4344 60.3034 53.1456 62.2466 57.3804C63.634 60.4044 65.4596 63.184 67.6418 65.641"
      fill="white"
    />
    <path
      d="M85.8463 76.2225C88.0626 76.632 90.3168 76.8436 92.61 76.86C111.051 76.86 126 61.9114 126 43.47C126 25.0293 111.051 10.08 92.61 10.08C87.8724 10.08 83.3666 11.0665 79.2842 12.8452C72.1904 15.9364 66.3788 21.4199 62.8689 28.2768C60.536 32.8342 59.22 37.9985 59.22 43.47C59.22 48.4344 60.3034 53.1456 62.2466 57.3804C63.634 60.4044 65.4596 63.184 67.6418 65.641"
      stroke="#394261"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M71.5642 69.3945C74.2707 71.5945 77.3287 73.3786 80.6413 74.6512"
      stroke="#394261"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path d="M127.26 76.86L134.82 84.42" stroke="#394261" strokeWidth="2.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.598 83.1978C131.214 85.583 131.214 89.4499 133.598 91.8338L147.566 105.802C149.95 108.186 153.817 108.186 156.202 105.802C158.587 103.417 158.587 99.5501 156.202 97.1661L142.235 83.1978C139.85 80.8139 135.983 80.8139 133.598 83.1978Z"
      fill="#E1E4ED"
      stroke="#394261"
      strokeWidth="2.5"
    />
    <path d="M139.86 85.68L153.72 99.54" stroke="#F6F7FC" strokeWidth="2.5" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.42 28.98C84.42 43.5935 96.2665 55.44 110.88 55.44C113.75 55.44 116.515 54.9826 119.103 54.1372C114.878 64.6216 104.609 72.0229 92.61 72.0229C76.8411 72.0229 64.0576 59.2389 64.0576 43.47C64.0576 29.1811 74.5542 17.3437 88.2567 15.2474C85.8224 19.2519 84.42 23.9522 84.42 28.98Z"
      fill="#E1E4ED"
    />
    <path
      d="M93.24 20.16C91.6361 20.16 90.0674 20.3179 88.5516 20.6189M83.9727 22.0199C75.3531 25.642 69.3 34.1641 69.3 44.1"
      stroke="#C1C7DC"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M160.242 43.8194H149.94M168.21 34.02H146.568M177.03 34.02H174.232"
      stroke="#C1C7DC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.8416 71.539H36.5401M40.9501 60.48H19.3086M11.9701 60.48H6.65137"
      stroke="#C1C7DC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const InnerContainer = styled.div(
  ({ theme }) => css`
    ${RowContentStyles}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: ${theme.spacings.md};
    gap: ${theme.spacings.sm};

    svg {
      max-width: 200px;
    }
  `,
);

const SubHeadline = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.text.secondary};
    margin-bottom: ${theme.spacings.md};
  `,
);

const DocumentationLink = styled.div`
  display: block;
`;

const PreviewIntro = () => {
  const theme = useTheme();

  return (
    <InnerContainer>
      {theme.mode === COLOR_SCHEME_LIGHT ? lightModeImage : darkModeImage}
      <h1>Data Lake Preview</h1>
      <SubHeadline>What can we help you find in your data lake?</SubHeadline>
      <DocumentationLink>
        <Link to="/">Link to documentation</Link>
      </DocumentationLink>
    </InnerContainer>
  );
};

export default PreviewIntro;
