import type { BulkActions } from 'security-app/types';
import type { LicenseSubject } from 'license/types';

const API_PLUGIN_ROUTE = '/plugins/org.graylog.plugins';

const EnterpriseApiRoutes = {
  Dashboards: {
    viewConfig: (viewId: string) => ({ url: `views/${viewId}` }),
    searchJob: (searchId: string) => ({ url: `views/search/${searchId}` }),
    searchJobResults: (searchId: string) => ({ url: `views/search/${searchId}/execute` }),
  },
  Illuminate: {
    bundles: {
      latest: {
        searchPacksPaginated: (params: string) => ({
          url: `${API_PLUGIN_ROUTE}.illuminate/bundles/latest/search_packs_paginated?${params}`,
        }),
        searchPacks: (params: string) => ({
          url: `${API_PLUGIN_ROUTE}.illuminate/bundles/latest/search_packs?${params}`,
        }),
        enablePacks: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/latest/enable_packs` }),
        disablePacks: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/latest/disable_packs` }),
        summary: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/latest/summary` }),
      },
      previous: {
        checkPrevious: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/previous` }),
        enablePrevious: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/previous/enable_bundle` }),
      },
      index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles` }),
      one: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}` }),
      status: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/status` }),
      upload: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/upload` }),
      packs: (bundleId: string) => ({
        index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs?${params}` }),
        update: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
      }),
      installStatus: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/installation_status` }),
      filterOptions: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/packs/filter_options` }),
      hub: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub` }),
        latest: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/latest` }),
        install: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/${bundleId}` }),
        releaseNotes: (version: string) => ({
          url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/release_notes/${version}`,
        }),
      },
    },
  },
  License: {
    list: () => ({ url: `${API_PLUGIN_ROUTE}.license/licenses/status/paginated` }),
    active: () => ({ url: `${API_PLUGIN_ROUTE}.license/licenses/status/active` }),
    save: () => ({ url: `${API_PLUGIN_ROUTE}.license/licenses` }),
    verify: () => ({ url: `${API_PLUGIN_ROUTE}.license/licenses/verify` }),
    delete: (queryParams?: string) => ({ url: `${API_PLUGIN_ROUTE}.license/licenses?${queryParams}` }),
    validityForSubject: (subject: LicenseSubject) => ({
      url: `${API_PLUGIN_ROUTE}.license/licenses/validity/for-subject?subject=${subject}`,
    }),
    metrics: () => ({ url: `${API_PLUGIN_ROUTE}.license/licenses/metrics` }),
    drawDownRemainingTraffic: (days: number) => ({
      url: `${API_PLUGIN_ROUTE}.license/licenses/traffic-remaining?days=${days}`,
    }),
    notifications: {
      acknowledgeUsageWarning: () => ({
        url: `${API_PLUGIN_ROUTE}.license/licenses/traffic-threshold/acknowledgement`,
      }),
    },
  },
  SecurityApp: {
    anomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config` }),
    updateAnomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/update_status` }),
    updateAnomalyEventDefinition: () => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/event_definition/update_status`,
    }),
    fetchDetectorDefinition: (id: string) => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config/${id}`,
    }),
    updateDetectorDefinition: (id: string) => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config/${id}`,
    }),
    createDetectorDefinition: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config` }),
    deleteDetectorDefinition: (id: string) => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config/${id}`,
    }),
    createSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules` }),
    uploadSigmaRules: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/upload` }),
    validateSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/validate` }),
    validateSigmaRuleZip: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/validate_zip` }),
    allSigmaRules: (options: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules?${options}` }),
    sigmaFilters: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/filter_options` }),
    oneSigmaRule: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}` }),
    importRepoSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/import` }),
    toggleSigmaRule: (id: string, action: 'enable' | 'disable') => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}/${action}`,
    }),
    sigmaBulkOperation: (action: BulkActions) => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/bulk/${action}`,
    }),
    sigmaGitRepos: (options?: string) => ({
      url: options
        ? `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/?${options}`
        : `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/`,
    }),
    sigmaGitRepo: (repoId: string, path: string = '', query: string = '') => {
      const route = `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/${repoId}`;

      const params = [];
      if (path) params.push(`path=${path}`);
      if (query) params.push(`query=${query}`);

      return { url: params.length > 0 ? `${route}?${encodeURI(params.join('&'))}` : route };
    },
    allSigmaRepos: (options: string) => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories?${options}`,
    }),
    bulkDeleteSigmaRepos: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/bulk/delete/` }),
    defaultSigmaRepo: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/default` }),
    downloadSigmaRule: (params: string) => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/download?${params}`,
    }),
    importAllRules: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/import_all` }),
    refreshRepoRules: (id: string) => ({
      url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/refresh/${id}`,
    }),
    Investigations: {
      index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations?${params}` }),
      filters: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/filter_options` }),
      update: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations` }),
      archive: (invId: string, archive: boolean) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/archive/${invId}/${archive}`,
      }),
      bulkArchive: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/archive` }),
      new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations` }),
      priorities: {
        index: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities?page=1&per_page=10000&sort=text&direction=asc`,
        }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities` }),
        reprioritize: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/reprioritize`,
        }),
        update: (priorityId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}`,
        }),
        delete: (priorityId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}`,
        }),
        setDefault: (priorityId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}/default`,
        }),
        removeDefault: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/default`,
        }),
      },
      statuses: {
        index: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses?page=1&per_page=10000&sort=status&direction=asc`,
        }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses` }),
        update: (statusId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}`,
        }),
        delete: (statusId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}`,
        }),
        setDefault: (statusId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}/default`,
        }),
        removeDefault: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/default`,
        }),
      },
      AIReport: {
        quotaStatus: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/summary/quota` }),
      },
      singleInvestigation: (invId: string) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}`,
      }),
      bulkDelete: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/bulk/delete` }),
      addEvidenceToInvestigation: (invId: string) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/evidence/${invId}`,
      }),
      bulkAddEvidenceToInvestigation: () => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/bulk/evidence/add`,
      }),
      removeEvidenceFromInvestigation: (invId: string) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/evidence/remove/${invId}`,
      }),
      addNoteToInvestigation: (invId: string) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes`,
      }),
      editInvestigationNote: (invId: string, noteId: string) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes/${noteId}`,
      }),
      deleteInvestigationNote: (invId: string, noteId: string) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes/${noteId}`,
      }),
      fetchUsers: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/users` }),
      fetchTeams: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/teams` }),
      fetchConfig: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/config` }),
      enableNotifications: (enabled: boolean) => ({
        url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/config/notifications/${enabled}`,
      }),
    },
    Teams: {
      index: () => ({ url: `${API_PLUGIN_ROUTE}.security/teams?page=1&per_page=10000` }),
    },
    Assets: {
      index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets?${params}` }),
      assetLabels: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/names?${params}` }),
      filters: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/filter_options` }),
      singleAsset: (assetId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/${assetId}` }),
      assetIdLookup: (query: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets?filters=id:${query}` }),
      new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets` }),
      bulkAddCategories: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/bulk/categories_add` }),
      priorities: {
        index: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities?page=1&per_page=10000&direction=asc`,
        }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities` }),
        reprioritize: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities/reprioritize` }),
        update: (priorityId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities/${priorityId}`,
        }),
        delete: (priorityId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities/${priorityId}`,
        }),
      },
      categories: {
        index: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories?page=1&per_page=10000&sort=status&direction=asc`,
        }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories` }),
        update: (categoryId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories/${categoryId}`,
        }),
        delete: (categoryId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories/${categoryId}`,
        }),
      },
      sources: {
        index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources?${params}` }),
        new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources` }),
        details: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}` }),
        update: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}` }),
        delete: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}` }),
        testNew: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/test` }),
        testSaved: (sourceId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/test`,
        }),
        bulkTest: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/bulk/test` }),
        ms365DeploymentTypes: () => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/ms365-deployment-types`,
        }),
        mappings: {
          index: (sourceId: string, params: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends?${params}`,
          }),
          new: (sourceId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends`,
          }),
          details: (sourceId: string, mappingId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/${mappingId}`,
          }),
          update: (sourceId: string, mappingId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/${mappingId}`,
          }),
          delete: (sourceId: string, mappingId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/${mappingId}`,
          }),
          testNew: (sourceId: string, mappingId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/use/${mappingId}/test`,
          }),
          testSaved: (sourceId: string, mappingId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/use/${mappingId}/test`,
          }),
          import: (sourceId: string, mappingId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/use/${mappingId}/import`,
          }),
          toggleSync: (sourceId: string, mappingId: string) => ({
            url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/${mappingId}/schedule`,
          }),
        },
      },
      scanners: {
        index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners?${params}` }),
        details: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners/${id}` }),
        new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners` }),
        delete: (scannerId: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners/${scannerId}`,
        }),
        edit: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners/${id}` }),
        testNewScannerConnection: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners/test` }),
        testScannerConnection: (id: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners/${id}/test`,
        }),
        import: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners/${id}/import` }),
        schedule: (id: string) => ({
          url: `${API_PLUGIN_ROUTE}.securityapp.asset/vulnerability_scanners/${id}/schedule`,
        }),
      },
    },
    EventProcedures: {
      index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/event_procedures?${params}` }),
      singleEventProcedure: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/event_procedures/${id}` }),
      steps: {
        index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/event_procedures/steps?${params}` }),
        singleEventProcedureStep: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/event_procedures/steps/${id}` }),
      },
    },
  },
  Welcome: {
    failures: {
      processingOverview: () => ({ url: `${API_PLUGIN_ROUTE}.failure/failures/count` }),
    },
  },
  ContentUI: {
    eventTypeCategory: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/event_type_category/ui_mapping` }),
    externalValueActions: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/external_actions/ui_mapping` }),
  },
  SecurityEvents: {
    details: (eventId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events/${eventId}` }),
    detailsByIds: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events/byIds` }),
    index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events?${params}` }),
    new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events` }),
    singleEvent: (eventId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events/${eventId}` }),
    users: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events/users` }),
    teams: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events/teams` }),
    notifications: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events/notifications` }),
    filters: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/security_events/filter_options` }),
    definitions: {
      index: (params: string) => ({ url: `events/definitions/paginated?${params}` }),
      details: (eventDefinitionId: string) => ({ url: `events/definitions/${eventDefinitionId}` }),
      detailsByIds: () => ({ url: 'events/definitions/byIds' }),
      types: () => ({ url: 'events/entity_types' }),
      schedule: (id: string) => ({ url: `events/definitions/${id}/schedule` }),
      unschedule: (id: string) => ({ url: `events/definitions/${id}/unschedule` }),
      duplicate: (id: string) => ({ url: `events/definitions/${id}/duplicate` }),
      clearQueue: (id: string) => ({ url: `events/definitions/${id}/clear-notification-queue` }),
      bulkSchedule: () => ({ url: 'events/definitions/bulk_schedule' }),
      bulkUnschedule: () => ({ url: 'events/definitions/bulk_unschedule' }),
      bulkDelete: () => ({ url: 'events/definitions/bulk_delete' }),
    },
    securityNotifications: {
      index: (params: string) => ({ url: `events/notifications/paginated?${params}` }),
      new: () => ({ url: 'events/notifications' }),
      details: (id: string) => ({ url: `events/notifications/${id}` }),
      test: (id: string) => ({ url: `events/notifications/${id}/test` }),
    },
  },
  DataTiering: {
    deleteFailedSnapshot: (indexSetId: string) => ({
      url: `${API_PLUGIN_ROUTE}.datatiering/datatiering/repositories/index_set/${indexSetId}/failed_snapshot`,
    }),
  },
};

export default EnterpriseApiRoutes;
