import * as React from 'react';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { Backend } from 'archive/types';
import { DATA_LAKE_BACKEND_CONFIG_QUERY_KEYS } from 'data-lake/Constants';
import type { DataLakeConfig } from 'data-lake/configurations/hooks/useDataLakeConfig';
import useDataLakeConfigMutation from 'data-lake/configurations/hooks/useDataLakeConfigMutation';
import { ConfirmDialog } from 'components/common';
import { Alert, Button } from 'components/bootstrap';

type Props = {
  backend: Backend;
  isActiveBackend: boolean;
  config: DataLakeConfig;
};

const DataLakeBackendActivateButton = ({ backend, isActiveBackend, config }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { onUpdateDataLakeConfig } = useDataLakeConfigMutation();
  const queryClient = useQueryClient();

  const onModalClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    setShowModal(true);
  };

  const onConfirm = () => {
    onUpdateDataLakeConfig({ config: { ...config, active_backend: backend.id } }).then(() => {
      queryClient.invalidateQueries(DATA_LAKE_BACKEND_CONFIG_QUERY_KEYS);
      onModalClose();
    });
  };

  return (
    <>
      {showModal && (
        <ConfirmDialog
          show={showModal}
          onConfirm={onConfirm}
          onCancel={onModalClose}
          title="Activate Data Lake backends ?">
          <>
            You are about to activate <strong>{backend.title}</strong> Data Lake backend.
            {!isActiveBackend && (
              <Alert bsStyle="danger">
                <p> You are about to switch currently enabled backend.</p>
                <p>Data already saved to this backend will not be moved to the newly enabled backend.</p>
                <p>Only data from the currently active backend is accessible from Graylog</p>
              </Alert>
            )}
            <p>Are you sure you want to continue?</p>
          </>
        </ConfirmDialog>
      )}
      {!isActiveBackend && (
        <Button bsStyle="default" bsSize="medium" onClick={onButtonClick}>
          Activate
        </Button>
      )}
    </>
  );
};

export default DataLakeBackendActivateButton;
