import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import type { FormikProps } from 'formik';

import StreamFilter from 'data-lake/preview/StreamFilter';
import SearchBarFieldsFilter from 'data-lake/preview/SearchBarFieldsFilter';
import RetrieveLogsAction from 'data-lake/preview/RetrieveLogsAction';
import {
  SearchBarContainer,
  TimeRangeRow,
  SEARCH_BAR_GAP,
  SearchQueryRow,
  SearchInputAndValidationContainer,
  SearchButtonAndQuery,
} from 'views/components/searchbar/SearchBarLayout';
import TimeRangeFilter from 'views/components/searchbar/time-range-filter';
import type { TimeRange } from 'views/logic/queries/Query';
import ViewsRefreshControls from 'views/components/searchbar/ViewsRefreshControls';
import SearchButton from 'views/components/searchbar/SearchButton';
import { Button } from 'components/bootstrap';
import type { FieldFilters, FieldsOperator } from 'data-lake/Types';
import { IfPermitted } from 'components/common';
import { WIDGET_DEFAULTS } from 'data-lake/preview/Constants';

export const WAHREHOUSE_PREVIEW_FORM_ID = 'data-lake-preview-form';

export type FormValues = {
  timerange: TimeRange;
  stream: string | undefined;
  fields: { fieldFilters: Array<FieldFilters>; operator: FieldsOperator };
};

const StreamsAndRefresh = styled.div`
  display: flex;
  gap: ${SEARCH_BAR_GAP};
  flex: 1.5;
`;

const validate = (formValues: FormValues) => {
  const errors = {};

  if (!formValues.stream) {
    return { ...errors, stream: 'Stream is required' };
  }

  return errors;
};

type Props = {
  initialValues: FormValues;
  onReset: () => void;
  onSubmit: (formValues: FormValues) => void;
  formRef: React.RefObject<FormikProps<FormValues>>;
};

const SearchBar = ({ onReset, initialValues, onSubmit, formRef }: Props) => {
  const _onSubmit = useCallback((formValues: FormValues) => onSubmit(formValues), [onSubmit]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={_onSubmit}
      validate={validate}
      innerRef={formRef}>
      {({ dirty, isSubmitting, isValid, isValidating, resetForm }) => {
        const disableSearchSubmit = isSubmitting || isValidating || !isValid;
        const resetSearch = () => {
          resetForm({
            values: {
              timerange: WIDGET_DEFAULTS.timerange,
              stream: undefined,
              fields: undefined,
            },
          });

          onReset();
        };

        return (
          <Form id={WAHREHOUSE_PREVIEW_FORM_ID}>
            <SearchBarContainer>
              <TimeRangeRow>
                <Field name="timerange">
                  {({ field: { name, value, onChange }, meta: { error } }) => (
                    <TimeRangeFilter
                      limitDuration={0}
                      onChange={(nextTimeRange) =>
                        onChange({
                          target: { value: nextTimeRange, name },
                        })
                      }
                      value={value}
                      hasErrorOnMount={!!error}
                    />
                  )}
                </Field>
                <StreamsAndRefresh>
                  <StreamFilter />
                  <ViewsRefreshControls />
                </StreamsAndRefresh>
              </TimeRangeRow>
              <SearchQueryRow>
                <SearchButtonAndQuery>
                  <SearchButton disabled={disableSearchSubmit} dirty={dirty} displaySpinner={isSubmitting} />
                  <SearchInputAndValidationContainer>
                    <SearchBarFieldsFilter />
                  </SearchInputAndValidationContainer>
                  <Button onClick={resetSearch} title="Clear filters and results">
                    Reset
                  </Button>
                  <IfPermitted permissions={['data_warehouse_archive:restore']}>
                    <RetrieveLogsAction />
                  </IfPermitted>
                </SearchButtonAndQuery>
              </SearchQueryRow>
            </SearchBarContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
export default SearchBar;
