import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { useMemo, useEffect, useCallback, useRef } from 'react';

import GenericPlot from 'views/components/visualizations/GenericPlot';
import { defaultCompare } from 'logic/DefaultCompare';

import type { CoverageEntity } from './types';

const CHART_HEIGHT = 300;

const Container = styled.div`
  flex: 1;
  min-width: 400px;
`;

const ChartWrapper = styled.div`
  height: ${CHART_HEIGHT}px;

  g.angularaxistick text {
    pointer-events: all;
    cursor: pointer;
  }
`;

const useChartData = (tactics: Array<CoverageEntity>) => {
  const theme = useTheme();

  return useMemo(() => {
    const tacticsSortedByName = [...tactics].sort(({ name: name1 }, { name: name2 }) => defaultCompare(name1, name2));

    return tacticsSortedByName.length > 0
      ? [
          {
            type: 'scatterpolar',
            r: [...tacticsSortedByName.map(({ coverage }) => coverage), tacticsSortedByName[0].coverage],
            theta: [...tacticsSortedByName.map(({ name }) => name), tacticsSortedByName[0].name],
            marker: {
              color: theme.colors.charts.cyan.markerColor,
            },
            line: {
              shape: 'spline',
            },
            selected: {
              marker: {
                size: 0,
              },
            },
            hovertemplate: '%{theta}: %{r}%',
            hoverlabel: {
              font: {
                color: theme.colors.charts.cyan.textColor,
              },
            },
            fill: 'toself',
            fillcolor: theme.colors.charts.cyan.fill,
            name: '',
          },
        ]
      : [];
  }, [
    tactics,
    theme.colors.charts.cyan.fill,
    theme.colors.charts.cyan.markerColor,
    theme.colors.charts.cyan.textColor,
  ]);
};

const useLayout = () => {
  const theme = useTheme();

  return useMemo(
    () => ({
      dragmode: false as const,
      polar: {
        bgcolor: 'transparent',
        radialaxis: {
          visible: true,
          angle: 90,
          range: [0, 105],
          rotation: 90,
          tickfont: {
            color: theme.colors.text.secondary,
          },
          gridcolor: theme.colors.charts.lines,
          gridwidth: 2,
        },
        angularaxis: {
          direction: 'clockwise' as const,
          rotation: 90,
          linewidth: 2,
          linecolor: 'transparent',
          tickfont: {
            color: theme.colors.text.primary,
          },
        },
        fixedrange: true,
      },
      scrollZoom: false,
      margin: {
        b: 40,
        t: 40,
      },
      showlegend: false,
      height: CHART_HEIGHT,
      font: {
        color: theme.colors.global.textDefault,
      },
    }),
    [
      theme.colors.charts.lines,
      theme.colors.global.textDefault,
      theme.colors.text.primary,
      theme.colors.text.secondary,
    ],
  );
};

type Props = {
  tactics: Array<CoverageEntity>;
  onSelectTactic?: (tacticName: string) => void;
};

const TacticsChart = ({ tactics, onSelectTactic = () => {} }: Props) => {
  const tacticLabels = useRef<NodeListOf<Element> | undefined>();
  const tacticLabelClickEvent = useRef<(e: MouseEvent) => void | undefined>();
  const chartData = useChartData(tactics);
  const layout = useLayout();

  const onAfterPlot = useCallback(() => {
    const axisTicks = document.querySelectorAll('g.angularaxistick');
    tacticLabels.current = axisTicks;

    const _onSelectTactic = (e: MouseEvent) => {
      const tacticName = (e.target as HTMLElement)?.dataset?.unformatted;

      const tactic = tactics.find(({ name }) => name === tacticName);

      if (tactic) {
        onSelectTactic(tactic.id);
      }
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const trigger of axisTicks) {
      trigger.addEventListener('click', _onSelectTactic);
    }

    tacticLabelClickEvent.current = _onSelectTactic;
  }, [onSelectTactic, tactics]);

  useEffect(
    () => () => {
      if (tacticLabels.current?.length > 0) {
        tacticLabels.current.forEach((element) => {
          element.removeEventListener('click', tacticLabelClickEvent.current);
        });
      }
    },
    [],
  );

  return (
    <Container>
      <h3>Posture</h3>
      <ChartWrapper>
        <GenericPlot chartData={chartData} onAfterPlot={onAfterPlot} layout={layout} />
      </ChartWrapper>
    </Container>
  );
};

export default TacticsChart;
