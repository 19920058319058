/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import { useMemo } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import type { SearchExecution, SearchExecutionResult } from 'views/types';
import useSearchExecutors from 'views/components/contexts/useSearchExecutors';
import type { SearchExecutors } from 'views/logic/slices/searchExecutionSlice';
import { searchExecutionSliceReducer } from 'views/logic/slices/searchExecutionSlice';

type Props = {
  executionState?: SearchExecutionState;
  result?: SearchExecutionResult;
};

type RootSearchStoreState = {
  searchExecution: SearchExecution;
};

const useInitialState = (
  executionState: SearchExecutionState,
  result: SearchExecutionResult,
): Partial<RootSearchStoreState> =>
  useMemo(
    () => ({
      searchExecution: {
        searchTypesToSearch: undefined,
        executionState,
        isLoading: false,
        result,
      },
    }),
    [executionState, result],
  );

const createStore = (
  reducers: Array<any>,
  initialState: Partial<RootSearchStoreState>,
  searchExecutors: SearchExecutors,
) => {
  const reducer = Object.fromEntries(reducers.map((r) => [r.key, r.reducer]));

  return configureStore({
    reducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: { searchExecutors },
        },
      }),
  });
};

const DataLakePreviewStoreProvider = ({
  children = undefined,
  executionState = SearchExecutionState.empty(),
  result = undefined,
}: React.PropsWithChildren<Props>) => {
  const reducers = useMemo(
    () => [
      {
        key: 'searchExecution',
        reducer: searchExecutionSliceReducer,
      },
    ],
    [],
  );
  const initialState = useInitialState(executionState, result);
  const searchExecutors = useSearchExecutors();
  const store = useMemo(
    () => createStore(reducers, initialState, searchExecutors),
    [initialState, reducers, searchExecutors],
  );

  return <Provider store={store}>{children}</Provider>;
};

export default DataLakePreviewStoreProvider;
