import Query from 'views/logic/queries/Query';
import Search from 'views/logic/search/Search';
import SearchResult from 'views/logic/SearchResult';
import { ICEBERG_QUERY } from 'data-lake/preview/Constants';
import type { FormValues } from 'data-lake/preview/SearchBar';
import useFetchActiveSearch from 'data-lake/preview/hooks/useFetchActiveSearch';

const useActiveSearch = (initialUrlParams: FormValues) => {
  const { data, isFetching: isLoading, refetch } = useFetchActiveSearch();
  const hasInitialUrlParams = Object.values(initialUrlParams).some((filter) => !!filter);

  if (
    hasInitialUrlParams ||
    !data ||
    (data.status !== 'DONE' && data.status !== 'RUNNING' && data.status !== 'EXPIRED')
  ) {
    return {
      search: undefined,
      result: undefined,
      isLoading,
      searchJobStatus: data?.status,
      refetch,
    };
  }

  const searchType = data.result.query.search_types[0];
  const query = Query.builder().id(data.result.query.id).query(ICEBERG_QUERY).searchTypes([searchType]).build();

  return {
    isLoading,
    search: Search.create().toBuilder().id(data.search_id).queries([query]).build(),
    result: {
      result: new SearchResult({
        results: { [data.result.query.id]: data.result },
        id: data.id,
        owner: data.owner,
        search_id: data.search_id,
        errors: data.error_message,
        execution: { done: true, cancelled: false, completed_exceptionally: false },
      }),
    },
    searchJobStatus: data.status,
    refetch,
  };
};

export default useActiveSearch;
