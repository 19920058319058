import { useQuery } from '@tanstack/react-query';

import { DataLake } from '@graylog/enterprise-api';

import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

type DataLakeField = {
  field_name: string;
  class: string;
};

const mapBackendType = (fieldType: string) => {
  switch (fieldType) {
    case 'java.lang.String':
      return 'string';
    default:
      return 'unknown';
  }
};

const identifyFieldType = (fieldType: string, fieldName: string) => {
  if (fieldName === 'streams') {
    return 'streams';
  }

  if (fieldName === 'gl2_source_input') {
    return 'input';
  }

  if (['source_ip', 'associated_ip', 'destination_ip'].includes(fieldName)) {
    return 'ip';
  }

  return mapBackendType(fieldType);
};

const deserializeFieldTypes = (fieldTypes: Array<DataLakeField>) =>
  fieldTypes.map(({ field_name: fieldName, class: fieldType }) =>
    FieldTypeMapping.fromJSON({
      name: fieldName,
      type: {
        type: identifyFieldType(fieldType, fieldName),
        properties: ['enumerable'],
        index_names: [],
      },
    }),
  );

const useFieldTypes = (): {
  data: Array<FieldTypeMapping>;
  refetch: () => void;
  isLoading?: boolean;
  isInitialLoading?: boolean;
} =>
  useQuery(['data-lake', 'field-types'], () => DataLake.filterableFields().then(deserializeFieldTypes), {
    staleTime: 30000,
  });

export default useFieldTypes;
