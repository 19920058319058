import * as React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components/common';
import { useGetSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';
import type { Attributes } from 'stores/PaginationTypes';
import { securityEventDetailsAttributes } from 'security-app/components/SecurityEvents/Alerts/Constants';

import ActionsRow from './ActionsRow';
import EventDetails from './EventDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

type Props = {
  securityEventId: string;
  onReplaySearch?: () => void;
  orientation?: 'horz' | 'vert';
  detailsAttributes?: Attributes;
};

function Index({
  securityEventId,
  onReplaySearch = () => {},
  orientation = 'horz',
  detailsAttributes = securityEventDetailsAttributes,
}: Props) {
  const { securityEvent, loadingSecurityEvents } = useGetSecurityEvent(securityEventId);

  return (
    <Container>
      {loadingSecurityEvents ? (
        <Spinner text="Loading Event ..." />
      ) : (
        <>
          <ActionsRow securityEvent={securityEvent} onReplaySearch={onReplaySearch} orientation={orientation} />
          <EventDetails securityEvent={securityEvent} orientation={orientation} detailsAttributes={detailsAttributes} />
        </>
      )}
    </Container>
  );
}

export default Index;
