import * as React from 'react';
import type * as Immutable from 'immutable';

import { singleton } from 'logic/singleton';
import type { LogViewMessage } from 'logview/types';
import type { LoadedPages } from 'logview/components/contexts/LogViewStateProvider';

export interface ListState {
  actions: {
    cancelLoadPrevPage: () => void;
    loadNextPage: () => void;
    loadPrevPage: () => void;
  };
  bottomPageId: number;
  loadedAllPrevMessages: boolean;
  pages: Immutable.OrderedSet<[number, Array<LogViewMessage>]>;
  allLoadedPages: LoadedPages;
}

const LogViewStateContext = React.createContext<ListState | undefined>(undefined);

export default singleton('contexts.enterprise.LogViewStateContext', () => LogViewStateContext);
