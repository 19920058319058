import * as React from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';

import { describeExpression } from 'util/CronUtils';
import { Button, Input } from 'components/bootstrap';
import { Switch, Spinner } from 'components/common';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';
import {
  SubmitOnValueChange,
  FormikMultiSelect,
  FormikTimeUnitInput,
  FormikUserTimezoneSelect,
  FormikMarkdownEditor,
  FormikSearchFiltersFormControl,
  useStreamOptions,
  useNotificationOptions,
} from 'security-app/components/common/FormikComponents';
import type { FormStateType } from 'security-app/components/common/FormikComponents';
import type { RuleOptionsType } from 'security-app/hooks/api/sigmaAPI.types';

import RuleOptionsReadOnly from './ReadOnly';
import { SEARCH_WITHIN_UNITS, EXECUTE_EVERY_UNITS, validate } from './utils';

const StyledForm = styled(Form)`
  width: 100%;

  & .form-group {
    width: 100%;
  }
`;

const StyledHelp = styled.small`
  white-space: pre-wrap;
`;

type Props = {
  initialValues: RuleOptionsType;
  formId?: string;
  onSubmit: (values: RuleOptionsType) => void;
  onCancel?: () => void;
  validateOnMount?: boolean;
  autoSubmit?: boolean;
  formState?: (value: FormStateType) => void;
  notMutable?: boolean;
  readOnly?: boolean;
};

function RuleOptionsForm({
  initialValues,
  formId = 'ruleOptionsForm',
  onSubmit,
  onCancel = () => {},
  validateOnMount = false,
  autoSubmit = false,
  formState = () => {},
  notMutable = false,
  readOnly = false,
}: Props) {
  const { streamOptions, streamCategoryOptions, loadingStreams } = useStreamOptions();
  const { notificationOptions, loadingNotifications } = useNotificationOptions();

  const handleCancel = (resetForm: () => void) => () => {
    resetForm();
    onCancel();
  };

  const preventSubmitOnEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') return false;

    return true;
  };

  if (readOnly) return <RuleOptionsReadOnly ruleOptions={initialValues} />;

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validate={validate} validateOnMount={validateOnMount}>
      {({ values, errors, isValid, dirty, submitForm, resetForm }) => (
        <StyledForm id={formId} onKeyDown={preventSubmitOnEnter}>
          <Col $width="100%">
            {loadingStreams ? (
              <Spinner text="Loading stream options" />
            ) : (
              <Field
                name="streams"
                component={FormikMultiSelect}
                matchProp="label"
                options={streamOptions}
                label="Streams"
                multiple
                menuPlacement="bottom"
                disabled={notMutable}
              />
            )}
            {streamCategoryOptions.length > 0 && (
              <Field
                name="stream_categories"
                component={FormikMultiSelect}
                matchProp="label"
                options={streamCategoryOptions}
                label="Stream categories"
                multiple
                menuPlacement="bottom"
                disabled={notMutable}
              />
            )}
            {loadingNotifications ? (
              <Spinner text="Loading notifications" />
            ) : (
              <Field
                name="notifications"
                component={FormikMultiSelect}
                matchProp="label"
                options={notificationOptions}
                label="Notifications"
                multiple
                menuPlacement="bottom"
              />
            )}
            <Field
              name="search_within"
              component={FormikTimeUnitInput}
              label="Search within"
              units={SEARCH_WITHIN_UNITS}
              errors={errors}
              disabled={notMutable}
            />
            <Row $width="100%" $fullWidth>
              <Field name="use_cron_scheduling" type="checkbox">
                {({ field }) => (
                  <Switch name={field.name}
                          id={`${formId}_${field.name}`}
                          label="Use cron scheduling"
                          checked={field.checked}
                          onChange={field.onChange}
                          disabled={notMutable} />
                )}
              </Field>
            </Row>
            {values.use_cron_scheduling ? (
              <Col $width="100%" $fullWidth>
                <Field name="cron_expression" style={{ width: '100%' }}>
                  {({ field }) => (
                    <Input
                      id={field.name}
                      name={field.name}
                      label="Cron expression"
                      type="text"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      help={
                        <StyledHelp>
                          {describeExpression(field.value) ||
                            'A Quartz cron expression to determine when the event should be run.'}
                        </StyledHelp>
                      }
                      bsStyle={field.error && field.touched ? 'error' : null}
                    />
                  )}
                </Field>
                <Field component={FormikUserTimezoneSelect} id="cron_timezone" name="cron_timezone" />
              </Col>
            ) : (
              <Field
                name="execute_every"
                component={FormikTimeUnitInput}
                label="Execute every"
                units={EXECUTE_EVERY_UNITS}
                errors={errors}
                disabled={notMutable}
              />
            )}
            <Row $fullWidth $width="100%">
              <Field component={FormikSearchFiltersFormControl} name="filters" label="Search Filters" />
            </Row>
            <Row $fullWidth $width="100%">
              <Field
                component={FormikMarkdownEditor}
                label="Remediation steps"
                id="remediation_steps"
                name="remediation_steps"
                disabled={notMutable}
              />
            </Row>
            {autoSubmit ? (
              <SubmitOnValueChange formState={formState} />
            ) : (
              <Row>
                <Button type="reset" bsSize="xs" onClick={handleCancel(resetForm)}>
                  Cancel
                </Button>
                <Button type="button" bsStyle="success" bsSize="xs" onClick={submitForm} disabled={!isValid || !dirty}>
                  Save Options
                </Button>
              </Row>
            )}
          </Col>
        </StyledForm>
      )}
    </Formik>
  );
}

export default RuleOptionsForm;
