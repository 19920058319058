import * as React from 'react';
import styled from 'styled-components';

import { Button, Alert } from 'components/bootstrap';
import { SourceCodeEditor } from 'components/common';
import { Modal } from 'common/components';
import useCurrentUser from 'hooks/useCurrentUser';
import useScopePermissions from 'hooks/useScopePermissions';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { useCreateSigmaRule, useValidateSigmaRule, useUpdateSigmaRule } from 'security-app/hooks/useSigmaAPI';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';
import RuleOptionsForm from 'security-app/components/SigmaRules/RuleOptionsForm';
import { formOptionsToPayload } from 'security-app/components/SigmaRules/RuleOptionsForm/utils';
import type { FormStateType } from 'security-app/components/common/FormikComponents';
import type {
  RuleOptionsType,
  SigmaRuleListAPIType,
  ManualRulePayloadType,
} from 'security-app/hooks/api/sigmaAPI.types';

const Buttons = ({
  onCancel,
  onConfirm,
  edit = false,
  confirmDisabled = false,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  edit?: boolean;
  confirmDisabled?: boolean;
}) => (
  <Row $width="100%" $gap="0.5rem" $justify="flex-end" $align="center">
    <Button type="reset" form="ruleOptions" onClick={onCancel}>
      Cancel
    </Button>
    <Button bsStyle="success" disabled={confirmDisabled} onClick={onConfirm}>
      {`${edit ? 'Save changes' : 'Add rule'}`}
    </Button>
  </Row>
);

const StyledAlert = styled(Alert)`
  margin-top: 15px;
`;

const INITIAL_RULE_OPTIONS = {
  source: '',
  search_within: 5,
  search_within_unit: 'MINUTES',
  execute_every: 5,
  execute_every_unit: 'MINUTES',
  use_cron_scheduling: false,
  cron_expression: '',
  cron_timezone: '',
  streams: [],
  stream_categories: [],
  notifications: [],
  filters: [],
  remediation_steps: '',
} as SigmaRuleListAPIType;

type Props = {
  rule?: SigmaRuleListAPIType;
  onConfirm: () => void;
  onCancel: () => void;
};

function SigmaModal({ rule = INITIAL_RULE_OPTIONS, onConfirm, onCancel }: Props) {
  const [currentRule, setCurrentRule] = React.useState<SigmaRuleListAPIType>(rule);
  const [ruleSchemaTouched, setRuleSchemaTouched] = React.useState<boolean>(false);
  const [debouncedSourceValidation, setDebouncedSourceValidation] = React.useState(null);
  const [validationErrorsArr, setValidationErrorsArr] = React.useState<string[]>([]);
  const [canSubmit, setCanSubmit] = React.useState<boolean>(false);
  const sendTelemetry = useSendTelemetry();

  const { scopePermissions } = useScopePermissions(rule);
  const isMutable = React.useMemo(() => scopePermissions?.is_mutable, [scopePermissions]);

  const { permissions } = useCurrentUser();
  const canManageRules = React.useMemo(
    () => permissions.includes('sigma_rules:edit') || permissions.includes('*'),
    [permissions],
  );

  const { createSigmaRule } = useCreateSigmaRule();
  const { updateSigmaRule } = useUpdateSigmaRule();
  const { validateSigmaRule } = useValidateSigmaRule();

  const validateRule = React.useCallback(
    async (rawRule: string) => {
      if (rawRule && canManageRules) {
        const validationResponse = await validateSigmaRule(rawRule);
        setValidationErrorsArr(validationResponse?.errors);
      }
    },
    [canManageRules, validateSigmaRule],
  );

  React.useEffect(() => {
    if (rule.id) validateRule(rule.source);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedRuleValidation = React.useCallback(
    (rawRule: string) => {
      if (debouncedSourceValidation) clearTimeout(debouncedSourceValidation);

      setDebouncedSourceValidation(
        setTimeout(() => {
          validateRule(rawRule);
        }, 800),
      );
    },
    [debouncedSourceValidation, validateRule],
  );

  const handleRuleInput = React.useCallback(
    (ruleYAML: string) => {
      setRuleSchemaTouched(true);
      setCurrentRule({ ...currentRule, source: ruleYAML });
      debouncedRuleValidation(ruleYAML);
    },
    [debouncedRuleValidation, currentRule],
  );

  const onOptionsChange = React.useCallback(
    (values: RuleOptionsType) => {
      setCurrentRule({ ...currentRule, ...values, source: currentRule.source });
    },
    [currentRule],
  );

  const handleFormValidState = (formState: FormStateType) => {
    const newState = rule.id ? (formState.isValid && (formState.dirty || ruleSchemaTouched)) : formState.isValid;
    setCanSubmit(newState && !!currentRule.source && validationErrorsArr.length < 1 && canManageRules);
  };

  const handleConfirm = async () => {
    const optionsPayload = formOptionsToPayload(currentRule);

    if (rule.id)
      await updateSigmaRule({ ...optionsPayload, id: rule.id, source: currentRule.source } as ManualRulePayloadType);
    else await createSigmaRule({ ...optionsPayload, source: currentRule.source } as ManualRulePayloadType);

    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP[`SIGMA_RULE_${rule.id ? 'UPDATED' : 'CREATED'}`], {
      app_pathname: 'security',
      app_section: 'sigma',
    });

    onConfirm();
  };

  return (
    <Modal
      show
      onClose={onCancel}
      title={`${rule.id ? 'Edit' : 'Add'} sigma rule`}
      maxWidth="700px"
      buttons={<Buttons onCancel={onCancel} edit={!!rule.id} onConfirm={handleConfirm} confirmDisabled={!canSubmit} />}>
      <Col $width="100%">
        <Row $width="100%" $fullWidth>
          <SourceCodeEditor
            id="sigmaEditor"
            mode="yaml"
            theme="light"
            height={400}
            value={currentRule.source}
            onChange={handleRuleInput}
            onBlur={() => validateRule(currentRule.source)}
            readOnly={!canManageRules || !isMutable}
          />
        </Row>
        <Row $width="100%" $fullWidth>
          {validationErrorsArr.length > 0 && (
            <StyledAlert bsStyle="danger">
              {validationErrorsArr.map((errorStr: string) => (
                <p key={errorStr}>{errorStr}</p>
              ))}
            </StyledAlert>
          )}
        </Row>
        <RuleOptionsForm
          onSubmit={onOptionsChange}
          initialValues={currentRule}
          autoSubmit
          validateOnMount={!!rule.id}
          formState={handleFormValidState}
          notMutable={!isMutable}
          readOnly={!canManageRules}
        />
      </Col>
    </Modal>
  );
}

export default SigmaModal;
