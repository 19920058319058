import { useContext, useEffect } from 'react';

import ExportSettingsContext from 'views/components/ExportSettingsContext';
import type LogViewWidgetConfig from 'logview/logic/LogViewWidgetConfig';
import ExportTimerangeContext from 'logview/components/contexts/ExportTimerangeContext';

type Props = {
  config: LogViewWidgetConfig;
};

const ExportSettingsTimerange = ({ config }: Props) => {
  const { setSettings } = useContext(ExportSettingsContext);
  const { first, last } = useContext(ExportTimerangeContext);

  useEffect(
    () =>
      setSettings({
        timerange:
          config.sort === 'ASC'
            ? { type: 'absolute', from: first, to: last }
            : { type: 'absolute', from: last, to: first },
      }),
    [config, first, last, setSettings],
  );

  return null;
};

export default ExportSettingsTimerange;
