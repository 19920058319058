import * as Immutable from 'immutable';

import DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import type Search from 'views/logic/search/Search';
import type { DataLakeSearchType } from 'data-lake/preview/Types';
import type { SearchType } from 'views/logic/queries/SearchType';
import { PAGE_SIZE, WIDGET_DEFAULTS } from 'data-lake/preview/Constants';

const defaultWidgetConfig = new DataLakeWidgetConfig(
  undefined,
  Immutable.OrderedSet(WIDGET_DEFAULTS.fields),
  PAGE_SIZE,
  WIDGET_DEFAULTS.sort,
  WIDGET_DEFAULTS.tieBreaker,
  WIDGET_DEFAULTS.fieldsOperator,
  Immutable.List(),
);

export const defaultWidget = DataLakeWidget.builder().id('data-lake-widget-id').config(defaultWidgetConfig).build();

const isDataLakeSearchType = (searchType: SearchType): searchType is DataLakeSearchType =>
  searchType.type === DataLakeWidget.type;

const createFromSearchType = (searchType: DataLakeSearchType) => {
  const { filters_operator, field_filters, search_after, fields } = searchType;

  return DataLakeWidgetConfig.fromJSON({
    fields,
    size: WIDGET_DEFAULTS.size,
    sort: WIDGET_DEFAULTS.sort,
    tie_breaker: WIDGET_DEFAULTS.tieBreaker,
    filters_operator,
    field_filters,
    after: search_after,
  });
};

const createInitialWidget = (previousSearch: Search | undefined) => {
  if (!previousSearch) {
    return defaultWidget;
  }

  const query = previousSearch.queries.first();
  const searchType = query.searchTypes[0];

  if (!searchType || !isDataLakeSearchType(searchType)) {
    return defaultWidget;
  }

  return defaultWidget
    .toBuilder()
    .config(createFromSearchType(searchType))
    .streams(searchType.streams ?? defaultWidget.streams)
    .timerange(query.timerange ?? defaultWidget.timerange)
    .build();
};

export default createInitialWidget;
