import * as React from 'react';
import { useCallback, useState } from 'react';

import { DataLakeQuery } from '@graylog/enterprise-api';

import { Button, Alert } from 'components/bootstrap';
import useFeature from 'hooks/useFeature';
import DataLakeRoutes from 'data-lake/Routes';
import useHistory from 'routing/useHistory';
import { ConfirmDialog } from 'components/common';
import useActiveSearch from 'data-lake/preview/hooks/useActiveSearch';

type Props = {
  streamId: string;
};

const PreviewLakeLogsButtonComponent = ({ streamId }: Props) => {
  const history = useHistory();
  const {
    isLoading: isLoadingActiveSearch,
    searchJobStatus,
    refetch,
  } = useActiveSearch({
    timerange: null,
    stream: null,
    fields: null,
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const onNavigate = useCallback(() => {
    history.push(`${DataLakeRoutes.PREVIEW}?streams=${streamId}`);
  }, [history, streamId]);
  const toggleShowConfirmation = useCallback(() => {
    setShowConfirmDialog((cur) => !cur);
  }, []);

  const handleOnClick = useCallback(() => {
    if (searchJobStatus === 'RUNNING') {
      toggleShowConfirmation();
    } else {
      onNavigate();
    }
  }, [onNavigate, searchJobStatus, toggleShowConfirmation]);

  const onClick = useCallback(
    () =>
      refetch().then(() => {
        handleOnClick();
      }),
    [handleOnClick, refetch],
  );

  const onConfirm = useCallback(() => {
    DataLakeQuery.resetActiveQuery().then(() => onNavigate());
  }, [onNavigate]);

  return (
    <>
      <Button bsSize="xs" title="Preview data lake logs" onClick={onClick} disabled={isLoadingActiveSearch}>
        Preview logs
      </Button>
      {showConfirmDialog && (
        <ConfirmDialog
          show={showConfirmDialog}
          onConfirm={onConfirm}
          onCancel={toggleShowConfirmation}
          title="A preview search is currently running">
          <Alert bsStyle="warning">
            A preview search is currently running. Continuing will cancel the ongoing search job. Do you want to
            proceed?
          </Alert>
        </ConfirmDialog>
      )}
    </>
  );
};

const PreviewLakeLogsButton = ({ streamId }: Props) => {
  const featureIsEnabled = useFeature('data_warehouse_search');
  if (!featureIsEnabled) {
    return null;
  }

  return <PreviewLakeLogsButtonComponent streamId={streamId} />;
};
export default PreviewLakeLogsButton;
