import __request__ from 'routing/request';
interface Startpage {
    readonly id: string;
    readonly type: string;
}
interface UserSummary {
    readonly service_account: boolean;
    readonly preferences: {
        readonly [_key: string]: Object;
    };
    readonly timezone: string;
    readonly session_active: boolean;
    readonly roles: string[];
    readonly last_name: string;
    readonly account_status: 'enabled' | 'disabled' | 'deleted';
    readonly auth_service_enabled: boolean;
    readonly external: boolean;
    readonly full_name: string;
    readonly session_timeout_ms: number;
    readonly startpage: Startpage;
    readonly last_activity: string;
    readonly read_only: boolean;
    readonly permissions: string[];
    readonly id: string;
    readonly client_address: string;
    readonly first_name: string;
    readonly email: string;
    readonly username: string;
    readonly grn_permissions: string[];
}
interface TokenList {
    readonly tokens: TokenSummary[];
}
interface Token {
    readonly last_access: string;
    readonly name: string;
    readonly id: string;
    readonly token: string;
}
interface TokenSummary {
    readonly last_access: string;
    readonly name: string;
    readonly id: string;
}
interface PermissionEditRequest {
    readonly permissions: string[];
}
interface ChangePasswordRequest {
    readonly old_password: string;
    readonly password: string;
}
interface PaginatedResponse_UserOverviewDTO {
    readonly [_key: string]: Object;
}
interface GenerateTokenTTL {
    readonly token_ttl: string;
}
interface UpdateUserPreferences {
    readonly preferences: {
        readonly [_key: string]: Object;
    };
}
interface ChangeUserRequest {
    readonly service_account: boolean;
    readonly startpage: Startpage;
    readonly session_timeout_ms: number;
    readonly permissions: string[];
    readonly timezone: string;
    readonly roles: string[];
    readonly last_name: string;
    readonly first_name: string;
    readonly email: string;
}
interface CreateUserRequest {
    readonly service_account: boolean;
    readonly password: string;
    readonly session_timeout_ms: number;
    readonly startpage: Startpage;
    readonly permissions: string[];
    readonly timezone: string;
    readonly roles: string[];
    readonly last_name: string;
    readonly first_name: string;
    readonly email: string;
    readonly username: string;
}
interface UserList {
    readonly users: UserSummary[];
}
/**
 * List all users
 */
export function listUsers(include_permissions: boolean = true, include_sessions: boolean = true): Promise<UserList> {
    return __request__('GET', '/users', null, { 'include_permissions': include_permissions, 'include_sessions': include_sessions }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new user account.
 * @param JSONbody Must contain username, full_name, email, password and a list of permissions.
 */
export function create(JSONbody: CreateUserRequest): Promise<unknown> {
    return __request__('POST', '/users', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get user details by userId
 * @param userId The userId to return information for.
 */
export function getbyId(userId: string): Promise<UserSummary> {
    return __request__('GET', `/users/id/${userId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Removes a user account.
 * @param userId The id of the user to delete.
 */
export function deleteUserById(userId: string): Promise<void> {
    return __request__('DELETE', `/users/id/${userId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get paginated list of users
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'title' | 'description' | 'full_name' = 'full_name', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_UserOverviewDTO> {
    return __request__('GET', '/users/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Modify user details.
 * @param userId The ID of the user to modify.
 * @param JSONbody Updated user information.
 */
export function changeUser(userId: string, JSONbody: ChangeUserRequest): Promise<void> {
    return __request__('PUT', `/users/${userId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update the password for a user.
 * @param userId The id of the user whose password to change.
 * @param JSONbody The old and new passwords.
 */
export function changePassword(userId: string, JSONbody: ChangePasswordRequest): Promise<void> {
    return __request__('PUT', `/users/${userId}/password`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update the account status for a user
 * @param userId The id of the user whose status to change.
 * @param newStatus The account status to be set
 */
export function updateAccountStatus(userId: string, newStatus: 'enabled' | 'disabled' | 'deleted' = 'enabled'): Promise<unknown> {
    return __request__('PUT', `/users/${userId}/status/${newStatus}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieves the list of access tokens for a user
 */
export function listTokens(userId: string): Promise<TokenList> {
    return __request__('GET', `/users/${userId}/tokens`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Removes a token for a user
 */
export function revokeToken(userId: string, idOrToken: string): Promise<void> {
    return __request__('DELETE', `/users/${userId}/tokens/${idOrToken}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Generates a new access token for a user
 * @param name Descriptive name for this token (e.g. 'cronjob') 
 * @param JSONBody Can optionally contain the token's TTL.
 */
export function generateNewToken(userId: string, name: string, JSONBody: GenerateTokenTTL = { "token_ttl": null }): Promise<Token> {
    return __request__('POST', `/users/${userId}/tokens/${name}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Removes a user account.
 * @param username The name of the user to delete.
 */
export function deleteUser(username: string): Promise<void> {
    return __request__('DELETE', `/users/${username}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get user details
 * @param username The username to return information for.
 */
export function get(username: string): Promise<UserSummary> {
    return __request__('GET', `/users/${username}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a user's permission set.
 * @param username The name of the user to modify.
 * @param JSONbody The list of permissions to assign to the user.
 */
export function editPermissions(username: string, JSONbody: PermissionEditRequest): Promise<void> {
    return __request__('PUT', `/users/${username}/permissions`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Revoke all permissions for a user without deleting the account.
 * @param username The name of the user to modify.
 */
export function deletePermissions(username: string): Promise<void> {
    return __request__('DELETE', `/users/${username}/permissions`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a user's preferences set.
 * @param username The name of the user to modify.
 * @param JSONbody The map of preferences to assign to the user.
 */
export function savePreferences(username: string, JSONbody: UpdateUserPreferences): Promise<void> {
    return __request__('PUT', `/users/${username}/preferences`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
