import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import { IconButton } from 'components/common';

import { FileListContainer, ListHeader, ListBody, FileListItem, FileName, DragMessage } from '../styled-components';
import { ACCEPTED_FILE_TYPES, getFilesWithOptions, useRuleSchemaValidation } from '../utils';
import type { FileFormType } from '../types';

type Props = {
  files: Array<FileFormType>;
  setFiles: (files: Array<FileFormType>) => void;
  selectedFile: FileFormType;
  setSelectedFile: (file: FileFormType) => void;
};

function FileList({ files, setFiles, selectedFile, setSelectedFile }: Props) {
  const { validateRuleSchemas } = useRuleSchemaValidation();

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const auxAcceptedFiles = acceptedFiles.map((file: File) => {
        if (!file.type) {
          const extension = file.name.split('.').pop();
          const type = Object.keys(ACCEPTED_FILE_TYPES).find((key: string) =>
            ACCEPTED_FILE_TYPES[key].includes(`.${extension}`),
          );

          return new File([file], file.name, { type });
        }

        return file;
      });

      const newFiles = getFilesWithOptions(auxAcceptedFiles);

      validateRuleSchemas(newFiles).then((validatedFiles: Array<FileFormType>) => {
        const filesToAdd = validatedFiles.filter(
          (newFileForm: FileFormType) =>
            !files.find(
              (fileForm: FileFormType) =>
                fileForm.file.name === newFileForm.file.name && fileForm.file.size === newFileForm.file.size,
            ),
        );

        if (!selectedFile) setSelectedFile(filesToAdd[0]);
        setFiles(filesToAdd);
      });
    },
    [files, setFiles, validateRuleSchemas, selectedFile, setSelectedFile],
  );

  const { getRootProps, getInputProps, isDragActive, isFileDialogActive, open } = useDropzone({
    accept: Object.values(ACCEPTED_FILE_TYPES).flat().join(','),
    noClick: true,
    onDrop,
  });

  const onSelectFile = React.useCallback(
    (inFileForm: FileFormType) => {
      setSelectedFile(undefined);
      setTimeout(() => setSelectedFile(inFileForm), 50);
    },
    [setSelectedFile],
  );

  const onRemoveFile = React.useCallback(
    (inFileId: string) => {
      setFiles(files.filter((file: FileFormType) => file.id !== inFileId));
    },
    [files, setFiles],
  );

  return (
    <FileListContainer>
      <ListHeader>
        Selected Files <IconButton name="add" title="Add rules" onClick={open} />
      </ListHeader>
      <ListBody {...getRootProps({ style: { cursor: 'default' } })} $isDragActive={isDragActive || isFileDialogActive}>
        {files.map((fileForm: FileFormType) => (
          <FileListItem
            key={fileForm.id}
            role="listitem"
            onClick={(e: React.BaseSyntheticEvent) => e.stopPropagation()}
            $active={fileForm.id === selectedFile?.id}>
            <IconButton name="close" title="Remove file" onClick={() => onRemoveFile(fileForm.id)} size="sm" />
            <FileName
              role="link"
              title="File name"
              $withError={fileForm.ruleErrors?.length > 0}
              onClick={() => onSelectFile(fileForm)}>
              {fileForm.file.name}
            </FileName>
          </FileListItem>
        ))}
        <input {...getInputProps({ id: 'ruleFiles', 'aria-label': 'Drop zone' })} />
        {files.length < 1 && <DragMessage>Drag &apos;n&apos; Drop your rules here</DragMessage>}
      </ListBody>
    </FileListContainer>
  );
}

export default FileList;
