import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  INPUT_WIZARD_STEPS,
  useInputSetupWizardSteps,
  useInputSetupWizardStepsHelper,
  ProgressMessage,
} from 'components/inputs/InputSetupWizard';
import { useGetInstallationStatus } from 'illuminate/hooks/useBundlesAPI';
import type { InstallStatus } from 'illuminate/types';

import useSetupIlluminate from './hooks/useSetupIlluminate';
import type { EnterpriseStepsData } from './types';

const StartIlluminate = () => {
  const { enableBundlesMutation } = useSetupIlluminate();
  const { stepsData } = useInputSetupWizardSteps<EnterpriseStepsData>();
  const { getStepData } = useInputSetupWizardStepsHelper<EnterpriseStepsData>();
  const illuminateStepData = getStepData(stepsData, INPUT_WIZARD_STEPS.SELECT_ILLUMINATE);
  const { installationStatus, gettingStatus } = useGetInstallationStatus();
  const [installationHasBeenStarted, setInstallationHasBeenStarted] = useState<boolean>(false);

  useEffect(() => {
    if (illuminateStepData?.selectedIlluminatePacks && illuminateStepData?.selectedIlluminatePacks.length > 0) {
      enableBundlesMutation.mutate({
        processing_pack_ids: illuminateStepData?.selectedIlluminatePacks,
        spotlight_pack_ids: illuminateStepData?.selectedSpotlightPacks,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [illuminateStepData?.selectedIlluminatePacks]);

  useEffect(() => {
    if (installationStatus.install_in_progress) {
      setInstallationHasBeenStarted(true);
    }
  }, [installationStatus]);

  if (!illuminateStepData?.selectedIlluminatePacks) return null;
  if (illuminateStepData?.selectedIlluminatePacks.length <= 0) return null;
  if (gettingStatus || (!installationHasBeenStarted && !installationStatus.install_in_progress)) return null;

  const installStatus = installationStatus as InstallStatus;

  return (
    <>
      {installStatus.install_progress.map((progressItem) => (
        <ProgressMessage
          isSuccess={progressItem.successful}
          isError={progressItem.complete && !progressItem.successful}
          isLoading={progressItem.install_in_progress}
          title={progressItem.title}
          details={progressItem.description}
        />
      ))}

      {installationHasBeenStarted && !installationStatus.install_in_progress && installStatus.previous_install && (
        <>
          <ProgressMessage
            isSuccess={installStatus.previous_install.successful}
            isError={!installStatus.previous_install.successful}
            isLoading={false}
            title={installStatus.previous_install.title}
            details={installStatus.previous_install.description}
          />
          {installStatus.previous_install.successful &&
            [
              ...installStatus.previous_install.pack_titles,
              ...installStatus.previous_install.spotlight_pack_titles,
            ].map((packTitle) => <ProgressMessage isSuccess isError={false} isLoading={false} title={packTitle} />)}
        </>
      )}
    </>
  );
};
export default StartIlluminate;
