import * as React from 'react';
import { styled } from 'styled-components';

import type { ColumnRenderers } from 'components/common/EntityDataTable';
import type { SecurityEventEntity } from 'security-app/hooks/api/securityEventsAPI.types';
import { getGeneralEventAttributeRenderers } from 'components/events/events/ColumnRenderers';
import { RiskScoreBadge } from 'security-app/components/common';
import useUsersAndTeams from 'security-app/components/Investigations/hooks/useUsersAndTeams';
import { SECURITY_EVENT_STATUSES } from 'security-app/hooks/api/securityEventsAPI.types';
import { useGetEventDefinitionDetailsBatched, useGetSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';
import EventDefinitionLink from 'components/events/events/EventDefinitionLink';
import Routes from 'security-app/routing/Routes';
import { Link } from 'components/common/router';

import AssigneeCell from '../../common/AssigneeCell';

const AssociatedInvestigations = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const OwnerCell = ({ owner }: { owner: string }) => {
  const usersAndTeams = useUsersAndTeams();

  return <>{usersAndTeams[owner] ? <AssigneeCell assignee={usersAndTeams[owner]} /> : <i>none</i>}</>;
};

const EventDefinitionRenderer = ({ eventDefinitionId }: { eventDefinitionId: string }) => {
  const { eventDefinition, loadingEventDefinition } = useGetEventDefinitionDetailsBatched(eventDefinitionId);
  const title = eventDefinition?.title;

  return !loadingEventDefinition && <EventDefinitionLink id={eventDefinitionId} title={title} />;
};

const AssociatedInvestigationsRenderer = ({ eventId }: { eventId: string }) => {
  const { securityEvent, loadingSecurityEvents } = useGetSecurityEvent(eventId);

  if (loadingSecurityEvents) return null;

  return (
    <AssociatedInvestigations>
      {securityEvent?.associated_investigations?.map((investigation) => (
        <Link role="link" to={Routes.INVESTIGATIONS.details(investigation.id)}>
          {investigation.name}
        </Link>
      ))}
    </AssociatedInvestigations>
  );
};

const customColumnRenderers: ColumnRenderers<SecurityEventEntity> = {
  attributes: {
    ...getGeneralEventAttributeRenderers<SecurityEventEntity>(),
    scores: {
      staticWidth: 100,
      renderCell: (scores: { [key: string]: number }) => <RiskScoreBadge riskScore={scores?.normalized_risk || 0} />,
    },
    owner: {
      renderCell: (owner: string) => <OwnerCell owner={owner} />,
      staticWidth: 200,
    },
    status: {
      renderCell: (status: string) => <span>{SECURITY_EVENT_STATUSES[status]}</span>,
      staticWidth: 120,
    },
    event_definition_id: {
      width: 0.2,
      renderCell: (eventDefinitionId: string) => <EventDefinitionRenderer eventDefinitionId={eventDefinitionId} />,
    },
    associated_investigations: {
      renderCell: (_, { event_id }) => <AssociatedInvestigationsRenderer eventId={event_id} />,
      width: 0.2,
      minWidth: 200,
    },
  },
};

export default customColumnRenderers;
