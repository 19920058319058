import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';

import { useGetLatestBundle, useGetHubBundles } from 'illuminate/hooks/useBundlesAPI';
import { Spinner } from 'components/common';
import { Alert, Button } from 'components/bootstrap';
import IlluminateNewBundleAlert from 'illuminate/components/common/header/IlluminateNewBundleAlert';
import { BundleContext } from 'illuminate/context/BundleContext';
import type { HubBundleType } from 'illuminate/types';
import useDownloadAndInstall from 'illuminate/components/illuminate/hooks/useDownloadAndInstall';

const FreshInstallMessage = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FirstIlluminateInstall = () => {
  const [selectedBundle, setSelectedBundle] = React.useState<HubBundleType>(null);

  const { hubBundles } = useGetHubBundles();
  const { onDownloadAndInstall, isInstalling } = useDownloadAndInstall(selectedBundle?.version);

  React.useEffect(() => setSelectedBundle(hubBundles[0]), [hubBundles]);

  return (
    <Alert bsStyle="warning">
      <FreshInstallMessage>
        <div>First you need to install Graylog Illuminate.</div>

        <Button
          type="button"
          bsStyle="success"
          disabled={!selectedBundle || isInstalling || selectedBundle?.installed || !selectedBundle?.is_compatible}
          onClick={onDownloadAndInstall}>
          {isInstalling && <Spinner text="" delay={0} />} Download & install
        </Button>
      </FreshInstallMessage>
    </Alert>
  );
};

const IlluminateBundleInstall = () => {
  const { isNew: illuminateUpdateIsAvailable, isInitialLoading } = useGetLatestBundle();
  const { bundleVersion, loadingBundles } = useContext(BundleContext);

  if (isInitialLoading || loadingBundles) {
    return <Spinner />;
  }

  if (!bundleVersion) {
    return <FirstIlluminateInstall />;
  }

  return (
    <>
      {!illuminateUpdateIsAvailable && (
        <Alert bsStyle="success">
          You installed the latest Illuminate version <b>{bundleVersion}</b>.
        </Alert>
      )}
      <IlluminateNewBundleAlert />
    </>
  );
};

export default IlluminateBundleInstall;
