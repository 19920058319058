import __request__ from 'routing/request';
interface BulkOperationResponse {
    readonly failures: BulkOperationFailure[];
    readonly successfully_performed: number;
    readonly errors: string[];
}
interface DataNodeDto {
    readonly data_node_status: 'UNCONFIGURED' | 'PREPARED' | 'STARTING' | 'AVAILABLE' | 'UNAVAILABLE' | 'REMOVING' | 'REMOVED';
    readonly is_master: boolean;
    readonly error_msg: string;
    readonly opensearch_roles: string[];
    readonly last_seen: string;
    readonly transport_address: string;
    readonly short_node_id: string;
    readonly object_id: string;
    readonly is_leader: boolean;
    readonly rest_api_address: string;
    readonly cert_valid_until: string;
    readonly version_compatible: boolean;
    readonly hostname: string;
    readonly datanode_version: string;
    readonly cluster_address: string;
    readonly id: string;
    readonly action_queue: 'REMOVE' | 'RESET' | 'STOP' | 'START' | 'REMOVED' | 'STOPPED' | 'STARTED' | 'CLEAR' | 'REQUEST_CSR' | 'REQUEST_CSR_WITH_AUTOSTART';
    readonly status: 'UNCONFIGURED' | 'PROVISIONED' | 'STARTING' | 'CONNECTED' | 'ERROR';
    readonly node_id: string;
}
interface BulkOperationFailure {
    readonly failure_explanation: string;
    readonly entity_id: string;
}
interface BulkOperationRequest {
    readonly entity_ids: string[];
}
/**
 * Remove multiple nodes from the cluster
 */
export function bulkRemove(Entitiestoremove: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/datanode/bulk_remove', Entitiestoremove, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Start multiple nodes in the cluster
 */
export function bulkStart(Entitiestostart: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/datanode/bulk_start', Entitiestostart, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Stop multiple nodes in the cluster
 */
export function bulkStop(Entitiestostop: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/datanode/bulk_stop', Entitiestostop, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns whether this Graylog is running against a data node search backend
 */
export function runsWithDataNode(): Promise<boolean> {
    return __request__('GET', '/datanode/configured', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get data node information
 */
export function getDataNode(nodeId: string): Promise<DataNodeDto> {
    return __request__('GET', `/datanode/${nodeId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove node from cluster
 */
export function removeNode(nodeId: string): Promise<DataNodeDto> {
    return __request__('DELETE', `/datanode/${nodeId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Reset a removed node to rejoin the cluster
 */
export function resetNode(nodeId: string): Promise<DataNodeDto> {
    return __request__('POST', `/datanode/${nodeId}/reset`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Start the OpenSearch process of a data node
 */
export function startNode(nodeId: string): Promise<DataNodeDto> {
    return __request__('POST', `/datanode/${nodeId}/start`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Stop the OpenSearch process of a data node
 */
export function stopNode(nodeId: string): Promise<DataNodeDto> {
    return __request__('POST', `/datanode/${nodeId}/stop`, null, {}, {
        'Accept': ['application/json']
    });
}
