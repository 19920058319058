import { useMemo, useCallback } from 'react';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';

import type { FormValues } from 'data-lake/preview/SearchBar';
import type { TimeRange } from 'views/logic/queries/Query';

type UrlQueryParams = {
  rangetype: string | undefined;
  relative: number | undefined;
  from: string | undefined;
  to: string | undefined;
  keyword: string | undefined;
};

const parseNestedObject = (fieldQueryString: string) => {
  try {
    return JSON.parse(decodeURIComponent(fieldQueryString));
  } catch (_error) {
    return null;
  }
};

const NestedObjectParam = {
  encode: (object: Object | null | undefined) => (object ? encodeURIComponent(JSON.stringify(object)) : undefined),
  decode: (objectStr: string | null | undefined) => parseNestedObject(objectStr),
};

const QUERY_PARAM_CONFIG_MAP = {
  rangetype: StringParam,
  from: StringParam,
  to: StringParam,
  range: NumberParam,
  streams: StringParam,
  keyword: StringParam,
  relative: NumberParam,
  q: StringParam,
  fields: NestedObjectParam,
};

const initialTimeRange = (queryParams: UrlQueryParams): TimeRange => {
  switch (queryParams.rangetype) {
    case 'relative':
      if (queryParams.relative !== undefined) {
        return {
          type: queryParams.rangetype,
          range: queryParams.relative,
        };
      }

      return {
        type: queryParams.rangetype,
        from: queryParams.from ? Number(queryParams.from) : undefined,
        to: queryParams.to ? Number(queryParams.to) : undefined,
      };
    case 'absolute':
      return {
        type: queryParams.rangetype,
        from: queryParams.from,
        to: queryParams.to,
      };
    case 'keyword':
      return {
        type: queryParams.rangetype,
        keyword: queryParams.keyword,
      };
    default:
      return undefined;
  }
};

const useUrlParams = () => {
  const [query, setQuery] = useQueryParams(QUERY_PARAM_CONFIG_MAP);

  const queryStream = query.streams?.split(',')[0];
  const urlParams = useMemo(
    () => ({
      timerange: initialTimeRange(query),
      stream: queryStream,
      fields: query.fields,
    }),
    [query, queryStream],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialUrlParams = useMemo(() => urlParams, []);

  const setUrlParams = useCallback(
    ({ timerange, stream, fields }: FormValues) => {
      setQuery({
        from: 'from' in timerange && timerange?.from !== undefined ? String(timerange.from) : undefined,
        to: 'to' in timerange && timerange?.to !== undefined ? String(timerange.to) : undefined,
        keyword: 'keyword' in timerange ? timerange?.keyword : undefined,
        rangetype: timerange?.type,
        range: 'range' in timerange ? timerange?.range : undefined,
        streams: stream,
        fields,
      });
    },
    [setQuery],
  );

  const resetUrlQueryParams = useCallback(() => {
    setQuery({
      from: undefined,
      to: undefined,
      keyword: undefined,
      rangetype: undefined,
      range: undefined,
      streams: undefined,
      fields: undefined,
    });
  }, [setQuery]);

  return { initialUrlParams, urlParams, setUrlParams, resetUrlQueryParams };
};

export default useUrlParams;
