import * as React from 'react';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, Checkbox, Label, ListGroup, ListGroupItem } from 'components/bootstrap';
import { IconButton, Spinner } from 'components/common';
import type { IlluminatePacks } from 'input-setup-wizard/hooks/useFilterIlluminatePacks';

const ScrollableListWrapper = styled.div`
  position: relative;
`;

const ScrollableList = styled(ListGroup)(
  ({ theme }) => css`
    margin-top: ${theme.spacings.xs};
    max-height: 425px;
    overflow-y: auto;
  `,
);

const StyledListGroupItem = styled(ListGroupItem)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    gap: ${theme.spacings.xs};
    padding-right: ${theme.spacings.xxs};
  `,
);

const EmptyListListGroupItem = styled(ListGroupItem)(
  ({ theme }) => css`
    text-align: center;
    padding-top: ${theme.spacings.md};
    padding-bottom: ${theme.spacings.md};
    font-style: italic;
  `,
);

const StyledCheckbox = styled(Checkbox)<{ $isExpanded: boolean }>(
  ({ $isExpanded }) => css`
    margin-top: 0;
    margin-bottom: 0;
    ${$isExpanded
      ? `
    align-self: flex-start;
  `
      : `
    align-self: center;
  `}
  `,
);

const TextWrapper = styled.div<{ $isExpanded: boolean }>(
  ({ $isExpanded }) => css`
    width: 100%;
    ${$isExpanded
      ? `
    align-self: flex-start;
  `
      : `
    align-self: center;
  `}
  `,
);

const PackText = styled.p`
  margin-bottom: 0;
`;

const StyledLabel = styled(Label)(
  ({ theme }) => css`
    margin-left: ${theme.spacings.xs};
  `,
);

const ListHeader = styled(ListGroupItem)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    gap: ${theme.spacings.xs};
    background-color: ${theme.colors.variant.lightest.default};
    color: ${theme.utils.contrastingColor(theme.colors.variant.lightest.default)};
    padding-bottom: ${theme.spacings.xs};
    padding-top: ${theme.spacings.xs};
    margin: 0;
  `,
);

type Props = {
  isLoading: boolean;
  listData: IlluminatePacks;
  selectedPacks: Array<string>;
  setSelectedPacks: (packs: Array<string>) => void;
  setTouched?: (touched: boolean) => void;
};

const IlluminateList = ({ isLoading, listData, selectedPacks, setSelectedPacks, setTouched = undefined }: Props) => {
  const [expandedPacks, setExpandedPacks] = useState<Array<string>>([]);

  const isPackExpanded = useCallback((packId) => expandedPacks.includes(packId), [expandedPacks]);
  const isPackSelected = useCallback((packId) => selectedPacks.includes(packId), [selectedPacks]);

  const onToggleExpandPack = (packId) => {
    if (!isPackExpanded(packId)) {
      setExpandedPacks([...expandedPacks, packId]);
    } else {
      setExpandedPacks(expandedPacks.filter((pack) => pack !== packId));
    }
  };

  const onSelectPack = (packId) => {
    if (setTouched) {
      setTouched(true);
    }

    if (!isPackSelected(packId)) {
      setSelectedPacks([...selectedPacks, packId]);
    } else {
      setSelectedPacks(selectedPacks.filter((pack) => pack !== packId));
    }
  };

  const onSelectAll = () => {
    if (setTouched) {
      setTouched(true);
    }

    if (!listData) return;

    const allIds = listData.map(({ pack_id }) => pack_id);
    setSelectedPacks(allIds);
  };

  const onDeselectAll = () => {
    if (setTouched) {
      setTouched(true);
    }

    setSelectedPacks([]);
  };

  if (isLoading) return <Spinner />;

  const noneSelected = selectedPacks.length <= 0;
  const allPacksSelected = selectedPacks.length === listData?.length;

  return (
    <ScrollableListWrapper>
      <ScrollableList>
        <ListHeader>
          <Button bsSize="small" onClick={onSelectAll} disabled={allPacksSelected}>
            Select all
          </Button>
          <Button bsSize="small" onClick={onDeselectAll} disabled={noneSelected}>
            Clear
          </Button>
        </ListHeader>
        {listData.length > 0 ? (
          listData.map((pack) => (
            <StyledListGroupItem key={`pack-${pack.pack_id}`}>
              <StyledCheckbox
                $isExpanded={isPackExpanded(pack.pack_id)}
                checked={isPackSelected(pack.pack_id)}
                onChange={() => onSelectPack(pack.pack_id)}
                name={`checkbox_${pack.pack_id}`}
                title={pack.title}
              />
              <TextWrapper $isExpanded={isPackExpanded(pack.pack_id)}>
                <PackText>
                  <strong>{pack.title}</strong>
                  {pack.enabled && <StyledLabel bsStyle="primary">Enabled</StyledLabel>}
                </PackText>
                {isPackExpanded(pack.pack_id) && <PackText>{pack.description}</PackText>}
              </TextWrapper>
              <IconButton
                onClick={() => onToggleExpandPack(pack.pack_id)}
                title="Read more"
                name={isPackExpanded(pack.pack_id) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              />
            </StyledListGroupItem>
          ))
        ) : (
          <EmptyListListGroupItem>We could not find any packs for your setup.</EmptyListListGroupItem>
        )}
      </ScrollableList>
    </ScrollableListWrapper>
  );
};

export default IlluminateList;
