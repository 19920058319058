import React, {useCallback, useState} from 'react';
import { useFormikContext } from 'formik';

import type {FormValues} from 'data-lake/preview/SearchBar';
import {Button} from 'components/bootstrap';
import RetrievalArchiveModal from 'data-lake/data-lake-overview/actions/RetrievalArchiveModal';

const RetrieveLogsAction = () => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const { values: { fields, stream } } = useFormikContext<FormValues>();

  const onToggleModal = useCallback(() => setShowRetrievalModal(cur => !cur), [])

  return <>
    <Button onClick={onToggleModal} title="Retrieve logs">Retrieve logs</Button>
    {showRetrievalModal && (
      <RetrievalArchiveModal archiveStreamId={stream}
                             onToggleModal={onToggleModal}
                             fieldOperator={fields?.operator}
                             fieldFilters={fields?.fieldFilters}
      />
    )}
  </>;
};

export default RetrieveLogsAction;
