import { useQuery } from '@tanstack/react-query';
import { create, windowScheduler, keyResolver } from '@yornaath/batshit';

import { DataLake } from '@graylog/enterprise-api';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';

import { DATA_LAKE_API_ROUTES } from '../Constants';
import type { StreamDataLakeConfig } from '../Types';

const dataLakeStatusses = create({
  fetcher: (ids: Array<string>) => DataLake.getStreamConfigs({ stream_ids: ids }),
  resolver: keyResolver('stream_id'),
  scheduler: windowScheduler(10),
});
export const fetchStreamDataLakeStatus = (streamId: string) =>
  fetch('GET', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.stream_data_warehouse_config(streamId).url));

const useStreamDataLakeStatus = (
  streamId: string,
): {
  data: StreamDataLakeConfig;
  refetch: () => void;
  isFetching: boolean;
  isError: boolean;
} => {
  const { data, refetch, isFetching, isError } = useQuery<StreamDataLakeConfig, Error>(
    ['data-lake-config', streamId, 'enabled'],
    () => dataLakeStatusses.fetch(streamId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  return {
    data,
    refetch,
    isFetching,
    isError,
  };
};

export default useStreamDataLakeStatus;
