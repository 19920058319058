import * as React from 'react';
import type Immutable from 'immutable';
import type { PluginExports } from 'graylog-web-plugin/plugin';

import {
  DATA_LAKE_ENABLED,
  DATALAKE_ATTRIBUTE_NAME,
  DATALAKE_ATTRIBUTES,
  DATA_LAKE_PREVIEW_NAV_ITEM,
} from 'data-lake/Constants';
import DataLakesPage from 'data-lake/DataLakesPage';
import DataLakeBackendPage from 'data-lake/data-lake-backend-config/DataLakeBackendPage';
import { isPermitted } from 'util/PermissionsMixin';
import StreamDataLake from 'data-lake/stream/StreamDataLake';
import DataLakeJobs from 'data-lake/components/DataLakeJobs';
import dataLakeCustomColumnRenderer from 'data-lake/DataLakeColumnRenderer';
import DataLakeConfigPage from 'data-lake/DataLakeConfigPage';
import StreamIndexSetDataLakeWarning from 'data-lake/stream/StreamIndexSetDataLakeWarning';
import DataLakeStreamDeleteWarning from 'data-lake/stream/DataLakeStreamDeleteWarning';
import { fetchStreamDataLake } from 'data-lake/hooks/useStreamDataLake';
import { fetchStreamDataLakeStatus } from 'data-lake/hooks/useStreamDataLakeStatus';
import StreamIlluminateProcessingSection from 'data-lake/stream/StreamIlluminateProcessingSection';
import validLicensePresent from 'license/ValidLicensePresent';
import DataLakeQueryValidation from 'data-lake/DataLakeQueryValidation';
import DataLakeJournal from 'data-lake/components/DataLakeJournal';
import DataLakeStatus from 'data-lake/components/DataLakeStatus';
import DataLakePreviewPage from 'data-lake/preview/DataLakePreviewPage';
import App from 'routing/App';
import DataLakeRoutes from 'data-lake/Routes';
import DestinationPermissionAlert from 'components/streams/StreamDetails/DestinationPermissionAlert';
import AppConfig from 'util/AppConfig';
import DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import DataLakeHandler from 'data-lake/logic/DataLakeHandler';

const isCloud = AppConfig.isCloud();
const ROUTES = [
  {
    path: DataLakeRoutes.unqualified.ARCHIVE.LIST,
    component: DataLakesPage,
  },
  {
    path: DataLakeRoutes.unqualified.CONFIGURATION,
    component: DataLakeConfigPage,
    permissions: 'data_warehouse_config:update',
  },
  {
    path: DataLakeRoutes.unqualified.PREVIEW,
    component: DataLakePreviewPage,
    parentComponent: App,
  },
];
const PREM_ONLY_ROUTES = [
  {
    path: DataLakeRoutes.unqualified.BACKEND,
    component: DataLakeBackendPage,
    permissions: 'data_warehouse_config:update',
  },
];

const routes = isCloud ? ROUTES : [...ROUTES, ...PREM_ONLY_ROUTES];

const getStreamDataLakeTableElements = (permissions: Immutable.List<string>) => {
  if (!isPermitted(permissions, 'data_warehouse_config:read')) {
    return null;
  }

  return {
    attributeName: DATALAKE_ATTRIBUTE_NAME,
    attributes: DATALAKE_ATTRIBUTES,
    columnRenderer: dataLakeCustomColumnRenderer,
  };
};

const dataLakeExport: PluginExports = {
  'views.elements.validationErrorExplanation': [
    (props) => (validLicensePresent() ? <DataLakeQueryValidation {...props} /> : null),
  ],
  routes: DATA_LAKE_ENABLED ? routes : [],
  dataLake: DATA_LAKE_ENABLED
    ? [
        {
          DataLakeJournal: DataLakeJournal,
          DataLakeStatus: DataLakeStatus,
          StreamDataLake: (props) =>
            isPermitted(props.permissions, 'data_warehouse:read') ? (
              <StreamDataLake />
            ) : (
              <DestinationPermissionAlert sectionName="Data Lake" />
            ),
          DataLakeJobs: (props) =>
            isPermitted(props.permissions, 'data_warehouse:read') ? <DataLakeJobs {...props} /> : null,
          StreamIndexSetDataLakeWarning: StreamIndexSetDataLakeWarning,
          fetchStreamDataLake: fetchStreamDataLake,
          fetchStreamDataLakeStatus: fetchStreamDataLakeStatus,
          DataLakeStreamDeleteWarning: DataLakeStreamDeleteWarning,
          getStreamDataLakeTableElements: getStreamDataLakeTableElements,
          StreamIlluminateProcessingSection,
        },
      ]
    : [],
  searchTypes: [
    {
      type: DataLakeWidget.type,
      handler: DataLakeHandler,
      defaults: {},
    },
  ],
  navigation: DATA_LAKE_ENABLED ? [DATA_LAKE_PREVIEW_NAV_ITEM] : [],
};

export default dataLakeExport;
