import * as React from 'react';
import { useCallback, useState } from 'react';
import { styled, css } from 'styled-components';

import { MenuItem } from 'components/bootstrap';
import BulkActionsDropdown from 'components/common/EntityDataTable/BulkActionsDropdown';
import BulkRetrievalModal from 'data-lake/preview/BulkRetrievalModal';

const Container = styled.div(
  ({ theme }) => css`
    padding-bottom: ${theme.spacings.sm};
    padding-top: ${theme.spacings.sm};
  `,
);

type Props = { streamId: string };

const BulkActions = ({ streamId }: Props) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);

  const toggleShowModal = useCallback(() => {
    setShowRetrievalModal((cur) => !cur);
  }, [setShowRetrievalModal]);

  return (
    <Container>
      <BulkActionsDropdown bsSize="xs">
        <MenuItem onSelect={toggleShowModal}>Retrieve Logs</MenuItem>
      </BulkActionsDropdown>
      {showRetrievalModal && <BulkRetrievalModal onCancel={toggleShowModal} streamId={streamId} />}
    </Container>
  );
};

export default BulkActions;
