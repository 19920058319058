import * as React from 'react';
import { useField } from 'formik';
import type { OrderedMap } from 'immutable';

import Store from 'logic/local-storage/Store';
import { SearchFiltersFormControls } from 'components/common';
import { ControlLabel } from 'components/bootstrap';
import { Col } from 'security-app/components/common/FormBaseStyles';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';

import { ErrorMessage } from './ErrorMessage';

export const PLUGGABLE_CONTROLS_HIDDEN_KEY = 'pluggableSearchBarControlsAreHidden';

type Props = {
  field: any;
  label?: string;
};

function FormikSearchFiltersFormControl({ field, label = null }: Props) {
  const [searchFiltersHidden, setSearchFiltersHidden] = React.useState(Store.get(PLUGGABLE_CONTROLS_HIDDEN_KEY));
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField({ name: field.name });

  const onChange = (filters: OrderedMap<string, SearchFilter>) => {
    setValue(filters.toArray());
    setTouched(true);
  };

  const hideFiltersPreview = (hideFliters: boolean) => {
    Store.set(PLUGGABLE_CONTROLS_HIDDEN_KEY, hideFliters);
    setSearchFiltersHidden(hideFliters);
  };

  const getSearchFilters = () => value || [];

  return !searchFiltersHidden ? (
    <Col $fullWidth $width="100%" $gap="0.3rem" $align="stretch">
      {label && <ControlLabel>{label}</ControlLabel>}
      <SearchFiltersFormControls
        filters={getSearchFilters()}
        onChange={onChange}
        hideFiltersPreview={hideFiltersPreview}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Col>
  ) : null;
}

export default FormikSearchFiltersFormControl;
