import * as React from 'react';

import { useGetInstalledBundles } from 'illuminate/hooks/useBundlesAPI';
import type { Bundles } from 'illuminate/types';

type BundleContextValues = {
  bundles: Bundles;
  loadingBundles: boolean;
  bundleVersion: string;
};

export const BundleContext = React.createContext<BundleContextValues>(null);

const BundleProvider = ({ children }: { children: React.ReactNode }) => {
  const { bundles, loadingBundles, bundleVersion } = useGetInstalledBundles();

  const bundleContextValue = React.useMemo(
    () => ({
      loadingBundles,
      bundleVersion,
      bundles,
    }),
    [bundles, loadingBundles, bundleVersion],
  );

  return <BundleContext.Provider value={bundleContextValue}>{children}</BundleContext.Provider>;
};

export default BundleProvider;
