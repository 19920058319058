import type { SyntheticEvent } from 'react';
import React, { useState } from 'react';
import { styled, css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import type { RestoreOperation, RestoreIndex } from 'data-lake/Types';
import { IfPermitted, Timestamp } from 'components/common';
import { LinkContainer } from 'components/common/router';
import SearchLink from 'views/logic/search/SearchLink';
import { createElasticsearchQueryString } from 'views/logic/queries/Query';
import { Row, Col, Table, Button, Badge, BootstrapModalConfirm, ButtonToolbar } from 'components/bootstrap';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';

type Props = {
  retrievalOperation: RestoreOperation;
  index: number;
  onDeleteOperation: (id: string) => Promise<void>;
};
const RetrievedStatusBadge = styled(Badge)(
  ({ theme }) => css`
    margin-left: ${theme.spacings.xxs};
  `,
);
const StyledTbody = styled.tbody<{
  $showIndices: boolean;
}>`
  ${({ $showIndices, theme }) =>
    $showIndices &&
    css`
      border-left: 4px solid ${theme.colors.variant.info};
    `}
  & > tr:is(:first-child) {
    cursor: pointer;
  }
`;
const StyledTable = styled(Table)(
  ({ theme }: { theme: DefaultTheme }) => css`
    margin-top: ${theme.spacings.sm};

    .table-hover > tbody > tr:hover {
      background: none;
    }
  `,
);
const StyledDetailTr = styled.tr(
  ({ theme }) => css`
    & > td {
      padding-left: ${theme.spacings.lg} !important;
      padding-top: ${theme.spacings.sm} !important;
    }
  `,
);

const DataLakeRetrievalTableEntry = ({ retrievalOperation, index, onDeleteOperation }: Props) => {
  const [showIndices, setShowIndices] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();
  const {
    restored_at,
    partially_restored,
    restored_documents,
    index_failures,
    timestamp_from,
    timestamp_to,
    restore_indices,
  } = retrievalOperation;

  const onConfirmDelete = () => {
    onDeleteOperation(retrievalOperation.id);
  };

  const handleDelete = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowConfirmDelete(true);

    sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.RETRIEVAL_OPERATION_DELETE_OPENED, {
      app_pathname: getPathnameWithoutId(pathname),
    });
  };

  const getIndicesQueryString = (restoreIndices: Array<RestoreIndex>) =>
    restoreIndices.reduce((acc, indice, currentIndex, arr) => {
      if (currentIndex + 1 === arr.length) {
        return `${acc}_index:${indice.index_name}`;
      }

      return `${acc}_index:${indice.index_name} OR `;
    }, '');

  return (
    <>
      <StyledTbody $showIndices={showIndices}>
        <tr key={restored_at} onClick={() => setShowIndices(!showIndices)}>
          <td>{index + 1}</td>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td>
            <Timestamp dateTime={restored_at} />
          </td>
          <td>
            <RetrievedStatusBadge bsStyle={partially_restored ? 'warning' : 'default'}>
              {partially_restored ? 'partially' : 'fully'} restored
            </RetrievedStatusBadge>
          </td>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td>
            <Timestamp dateTime={timestamp_from} />
          </td>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td>
            <Timestamp dateTime={timestamp_to} />
          </td>
          <td>{restored_documents}</td>
          <td>{index_failures}</td>
          <td>{restore_indices.length}</td>
          <td>
            <ButtonToolbar className="pull-right">
              <LinkContainer
                key={`received-messages-${index}`}
                to={SearchLink.builder()
                  .query(createElasticsearchQueryString(getIndicesQueryString(restore_indices)))
                  .timerange({
                    type: 'absolute',
                    from: timestamp_from,
                    to: timestamp_to,
                  })
                  .build()
                  .toURL()}>
                <Button
                  bsSize="xsmall"
                  onClick={() => {
                    sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.RETRIEVAL_OPERATION_VIEW_CLICKED, {
                      app_pathname: getPathnameWithoutId(pathname),
                    });
                  }}>
                  Show messages
                </Button>
              </LinkContainer>
              <IfPermitted permissions={['data_warehouse_archive:delete']}>
                <Button bsSize="xsmall" bsStyle="danger" onClick={handleDelete}>
                  Delete
                </Button>
              </IfPermitted>
            </ButtonToolbar>
          </td>
        </tr>
        {showIndices && (
          <StyledDetailTr>
            <td colSpan={9}>
              <Row>
                <Col md={6}>
                  <p>List of indices created during the retrieval operation.</p>
                  <StyledTable condensed hover>
                    <thead>
                      <tr>
                        <th>Indices</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {restore_indices.map((indice) => (
                        <tr key={indice.index_name}>
                          <td>{indice.index_name}</td>
                          <td>
                            <LinkContainer
                              key={`received-messages-${indice.index_name}`}
                              to={SearchLink.builder()
                                .query(createElasticsearchQueryString(`_index:${indice.index_name}`))
                                .timerange({
                                  type: 'absolute',
                                  from: timestamp_from,
                                  to: timestamp_to,
                                })
                                .build()
                                .toURL()}>
                              <Button bsSize="xsmall">Show messages</Button>
                            </LinkContainer>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                </Col>
              </Row>
            </td>
          </StyledDetailTr>
        )}
      </StyledTbody>
      {showConfirmDelete && (
        <BootstrapModalConfirm
          showModal={showConfirmDelete}
          onConfirm={() => onConfirmDelete()}
          onCancel={() => setShowConfirmDelete(false)}
          title="Are you sure?">
          <>
            <p>
              You are about to delete retrieval operation done at{' '}
              <b>
                <Timestamp dateTime={restored_at} />
              </b>
              . This will delete <b>{restore_indices?.length}</b> indices.
            </p>

            <p>Do you want to continue?</p>
          </>
        </BootstrapModalConfirm>
      )}
    </>
  );
};

export default DataLakeRetrievalTableEntry;
