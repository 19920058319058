import { useQuery, useMutation } from '@tanstack/react-query';

import Store from 'logic/local-storage/Store';
import type { PacksIndexAPIType, PacksSummary, Pack, PackFiltersType } from 'illuminate/types';
import {
  fetchPacks,
  updatePacks,
  fetchLatestPacks,
  fetchPacksNoPaginated,
  fetchPacksSummary,
  latestEnablePacks,
  latestDisablePacks,
  fetchFilterOptions,
} from 'illuminate/hooks/api/packs';
import UserNotification from 'util/UserNotification';

type PaginatedProps = {
  page: number;
  perPage: number;
  filters?: {};
  query?: string;
  includeDeps?: boolean;
};

export function useGetPacks({ page, perPage, query, includeDeps }: PaginatedProps, bundleVersion?: string) {
  const { isLoading, isInitialLoading, data, isError, error } = useQuery<PacksIndexAPIType, Error>(
    ['illuminate-bundle-packs', page, perPage, query, bundleVersion, includeDeps],
    () => fetchPacks(bundleVersion, page, perPage, query, includeDeps),
    {
      enabled: true,
      retry: 1,
    },
  );

  if (isError && error) UserNotification.error(error.message);

  const packs = data?.packs || [];

  return {
    packs: packs,
    loadingPacks: isLoading,
    isInitialLoading,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetLatestPacks({ page, perPage, query, filters, includeDeps }: PaginatedProps) {
  const { isLoading, isInitialLoading, data, isError, error } = useQuery<PacksIndexAPIType, Error>(
    ['latest-illuminate-bundle-packs', page, perPage, filters, query, includeDeps],
    () => fetchLatestPacks(page, perPage, query, filters, includeDeps),
    {
      enabled: true,
      retry: 1,
    },
  );

  if (isError && error) UserNotification.error(error.message);

  const packs = data?.packs || [];

  return {
    packs: packs,
    loadingPacks: isLoading,
    isInitialLoading,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetFilterOptions() {
  const { mutateAsync, isLoading } = useMutation(fetchFilterOptions, {
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    getFilterOptions: mutateAsync,
    gettingFilterOptions: isLoading,
  };
}

export function useGetFilteredPacks(filters: PackFiltersType) {
  const { data, isLoading } = useQuery<Pack[], Error>(['latest-packs-no-paginated', filters], () =>
    fetchPacksNoPaginated(filters),
  );

  return {
    filteredPacks: data || [],
    loadingFilteredPacks: isLoading,
  };
}

export const ILLUMINATE_STORE_VERSION_KEY = 'illuminate_skipped_bundle_version';

export function useGetPacksSummary() {
  const { data, isLoading } = useQuery<PacksSummary, Error>(['latest-packs-summary'], fetchPacksSummary, {
    refetchInterval: 60 * 60 * 1000,
  });

  const skippedVersion = Store.get(ILLUMINATE_STORE_VERSION_KEY) || '0.0.1';

  return {
    packsSummary: data || { new_packs_available: false, updated_packs_available: false, version: skippedVersion },
    loadingPacksSummary: isLoading,
  };
}

export function useUpdatePacks() {
  const { mutateAsync, isLoading } = useMutation(updatePacks);

  return { updatePacks: mutateAsync, updatingPacks: isLoading };
}

export function useEnableLatestPacks() {
  const { mutateAsync, isLoading } = useMutation(latestEnablePacks);

  return { enablePacks: mutateAsync, enablingPacks: isLoading };
}

export function useDisableLatestPacks() {
  const { mutateAsync, isLoading } = useMutation(latestDisablePacks);

  return { disablePacks: mutateAsync, disablingPacks: isLoading };
}
