import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import { defaultCompare } from 'logic/DefaultCompare';
import type { Stream } from 'views/stores/StreamsStore';
import { defaultOnError } from 'util/conditional/onError';

import {
  bulkSigmaRule,
  bulkAddNotifications,
  bulkSigmaRepoDelete,
  fetchSigmaRules,
  fetchStreams,
  fetchNotifications,
  createSigmaRule,
  updateSigmaRule,
  toggleSigmaRule,
  deleteSigmaRule,
  listGitRepos,
  addGitRepo,
  updateGitRepo,
  deleteSigmaRepo,
  listRepoContent,
  importRulesFromRepo,
  validateSigmaRule,
  addDefaultRepo,
  importAllRulesFromRepo,
  refreshRepoRules,
  uploadSigmaRules,
  validateSigmaRuleZip,
  getSigmaRule,
  fetchSigmaRulesFilterOptions,
} from './api/sigmaAPI';
import type {
  ImportAPIResponse,
  SigmaRulesAPIResponseType,
  GitRepoContent,
  SigmaReposAPIResponseType,
  NotificationsResponseType,
  SigmaRuleListAPIType,
} from './api/sigmaAPI.types';

export type PaginationOptionsType = {
  page: number;
  perPage: number;
  query?: string;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  filters?: ColumnFilterData;
};

export const sigmaRuleKey = 'get-sigma-rule';
const sigmaRulesKey = 'get-sigma-rules';

export function useGetSigmaRules(
  { page, perPage, query, orderBy, direction, filters }: PaginationOptionsType,
  userTimezone?: string,
) {
  const { data, isLoading } = useQuery<SigmaRulesAPIResponseType, Error>(
    [sigmaRulesKey, page, perPage, query, orderBy, direction, filters, userTimezone],
    () =>
      defaultOnError(
        fetchSigmaRules(page, perPage, query, orderBy, direction, filters, userTimezone),
        'Error fetching Sigma rules',
      ),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingRules: isLoading,
    rules: isLoading ? [] : data?.sigma_rules,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useFetchSigmaRulesFilterOptions() {
  const { mutateAsync, isLoading } = useMutation(fetchSigmaRulesFilterOptions, {
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    fetchSigmaRulesFilterOptions: mutateAsync,
    fetchingSigmaRulesFilterOptions: isLoading,
  };
}

export function useGetSigmaRule(ruleId: string) {
  const { data, isLoading } = useQuery<SigmaRuleListAPIType, Error>(
    [sigmaRuleKey, ruleId],
    () => defaultOnError(getSigmaRule(ruleId), 'Error fetching Sigma Rule'),
    {
      retry: false,
      enabled: !!ruleId,
    },
  );

  return {
    loadingRule: isLoading,
    rule: data,
  };
}

export function useGetStreams(execute: boolean = true) {
  const { data, isLoading } = useQuery<
    { total: number; streams: { id: string; title: string; is_editable: boolean; categories: string[] }[] },
    Error
  >(['streams-index'], () => defaultOnError(fetchStreams(), 'Error fetching streams'), {
    retry: 1,
    enabled: execute,
  });

  return {
    loadingStreams: isLoading && !!execute,
    streams:
      data?.streams.sort((a: { id: string; title: string }, b: { id: string; title: string }) => {
        if (a.title > b.title) return 1;
        if (a.title < b.title) return -1;

        return 0;
      }) || [],
    streamCategories:
      data?.streams
        .reduce((acc, stream: Stream) => {
          stream.categories?.forEach((category: string) => {
            if (!acc.includes(category)) acc.push(category);
          });

          return acc;
        }, [])
        .sort((a, b) => defaultCompare(a, b)) || [],
  };
}

export function useGetNotifications(execute: boolean = true) {
  const { data, isLoading } = useQuery<NotificationsResponseType, Error>(
    ['notifications-index'],
    () => defaultOnError(fetchNotifications(), 'Error fetching notifications'),
    {
      retry: 1,
      enabled: execute,
    },
  );

  return {
    loadingNotifications: isLoading && !!execute,
    notifications:
      data?.notifications.sort((a: { id: string; title: string }, b: { id: string; title: string }) => {
        if (a.title > b.title) return 1;
        if (a.title < b.title) return -1;

        return 0;
      }) || [],
  };
}

export function useValidateSigmaRule() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(validateSigmaRule, {
    onSuccess: () => queryClient.invalidateQueries(['validate-sigma-rule']),
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { validateSigmaRule: mutateAsync, isValidating: isLoading };
}

export function useCreateSigmaRule() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(createSigmaRule, {
    onSuccess: () => {
      UserNotification.success('Rule created');
      queryClient.invalidateQueries([sigmaRulesKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { createSigmaRule: mutateAsync };
}

export function useUpdateSigmaRule() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateSigmaRule, {
    onSuccess: () => {
      UserNotification.success('Rule updated');
      queryClient.invalidateQueries([sigmaRulesKey]);
      queryClient.invalidateQueries([sigmaRuleKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { updateSigmaRule: mutateAsync };
}

export function useToggleSigmaRule() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(toggleSigmaRule, {
    onSuccess: () => {
      queryClient.invalidateQueries([sigmaRulesKey]);
      queryClient.invalidateQueries(['threat-coverage']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { toggleSigmaRule: mutateAsync };
}

export function useDeleteSigmaRule() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(deleteSigmaRule, {
    onSuccess: () => queryClient.invalidateQueries([sigmaRulesKey]),
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { deleteSigmaRule: mutateAsync };
}

export function useListGitRepos(
  { page, perPage, query, orderBy, direction }: PaginationOptionsType,
  enabled: boolean = true,
) {
  const { data, isLoading } = useQuery<SigmaReposAPIResponseType, Error>(
    ['list-git-repos', page, perPage, query, orderBy, direction],
    () => defaultOnError(listGitRepos(page, perPage, query, orderBy, direction), 'Error fetching Git repositories'),
    {
      retry: 1,
      enabled: enabled,
      keepPreviousData: true,
    },
  );

  return {
    loadingRepos: isLoading,
    repos: isLoading ? [] : data?.sigma_git_repositories,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useListRepoContent(repoId: string, path: string, query: string) {
  const { data, isLoading } = useQuery<GitRepoContent[], Error>(
    ['list-git-repo-content', repoId, path, query],
    () => defaultOnError(listRepoContent(repoId, path, query), 'Error listing repository'),
    {
      retry: 1,
      enabled: !!repoId,
    },
  );

  return {
    loadingRepoRules: isLoading && !!repoId,
    repoRules: data
      ? data.sort((a: GitRepoContent, b: GitRepoContent) => {
          if (a.type > b.type) return 1;
          if (a.type < b.type) return -1;

          return 0;
        })
      : null,
  };
}

export function useAddGitRepo() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(addGitRepo, {
    onSuccess: (data: ImportAPIResponse) => {
      if (data.failure_count > 0) {
        const error =
          data.success_count > 0
            ? {
                type: 'warning',
                message: `Repo added and imported ${data.success_count} rules successfully, ${data.failure_count} rules failed to import.`,
              }
            : { type: 'error', message: `Failed to add repo: ${data.errors}` };

        UserNotification[error.type](error.message);
      } else {
        UserNotification.success(`Repo added and all ${data.success_count} rules imported`);
      }

      queryClient.invalidateQueries(['list-git-repos']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { addGitRepo: mutateAsync, addingRepo: isLoading };
}

export function useAddDefaultGitRepo() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(addDefaultRepo, {
    onSuccess: () => queryClient.invalidateQueries(['list-git-repos']),
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { addDefaultRepo: mutateAsync, addingDefaultRepo: isLoading };
}

export function useUpdateGitRepo() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateGitRepo, {
    onSuccess: (data: ImportAPIResponse) => {
      if (data.failure_count > 0) {
        const error =
          data.success_count > 0
            ? {
                type: 'warning',
                message: `Repo updated: updated ${data.success_count} rules successfully, ${data.failure_count} rules failed to update.`,
              }
            : { type: 'error', message: `Failed to update repo: ${data.errors}` };

        UserNotification[error.type](error.message);
      } else {
        UserNotification.success(`Repo updated with all ${data.success_count} rules.`);
      }

      queryClient.invalidateQueries(['list-git-repos']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { updateGitRepo: mutateAsync, updatingRepo: isLoading };
}

export function useImportRulesFromRepo() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(importRulesFromRepo, {
    onSuccess: (data: ImportAPIResponse) => {
      if (data.failure_count > 0) {
        const error =
          data.success_count > 0
            ? { type: 'warning', message: 'Rules imported with errors' }
            : { type: 'error', message: 'All rules failed to import' };

        UserNotification[error.type](error.message);
      } else {
        UserNotification.success(`All ${data.success_count} rules imported`);
      }

      queryClient.invalidateQueries([sigmaRulesKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    importRulesFromRepo: mutateAsync,
    importingRules: isLoading,
  };
}

export function useImportAllRulesFromRepo() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(importAllRulesFromRepo, {
    onSuccess: (data: ImportAPIResponse) => {
      if (data.failure_count > 0) {
        const warnMsg = `${data.success_count} rule${data.success_count === 1 ? '' : 's'} imported successfully. ${data.failure_count} rule${data.failure_count === 1 ? '' : 's'} failed to import. See server log for more detailed information.`;
        const error =
          data.success_count > 0
            ? { type: 'warning', message: warnMsg }
            : { type: 'error', message: 'All rules failed to import' };

        UserNotification[error.type](error.message);
      } else {
        UserNotification.success(`All ${data.success_count} rules imported`);
      }

      queryClient.invalidateQueries([sigmaRulesKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    importRulesFromRepo: mutateAsync,
    importingRules: isLoading,
  };
}

export function useRefreshRepoRules() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(refreshRepoRules, {
    onSuccess: (data: ImportAPIResponse) => {
      if (data.failure_count > 0) {
        const warnMsg = `${data.success_count} rule${data.success_count === 1 ? '' : 's'} refreshed successfully. ${data.failure_count} rule${data.failure_count === 1 ? '' : 's'} failed to refresh. See server log for more detailed information.`;
        const error =
          data.success_count > 0
            ? { type: 'warning', message: warnMsg }
            : { type: 'error', message: 'All rules failed to refresh' };

        UserNotification[error.type](error.message);
      } else {
        UserNotification.success(`All ${data.success_count} rules refreshed`);
      }

      queryClient.invalidateQueries([sigmaRulesKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    refreshRules: mutateAsync,
    refreshingRules: isLoading,
  };
}

export function useDeleteSigmaRepo() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteSigmaRepo, {
    onSuccess: () => queryClient.invalidateQueries(['list-git-repos']),
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { deleteSigmaRepo: mutateAsync, deletingRepo: isLoading };
}

export function useBulkSigmaOperations() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(bulkSigmaRule, {
    onSuccess: () => {
      UserNotification.success('Action completed. ');
      queryClient.invalidateQueries([sigmaRulesKey]);
      queryClient.invalidateQueries(['threat-coverage']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { bulkSigmaOperations: mutateAsync, isMutating: isLoading };
}

export function useBulkAddNotifications() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(bulkAddNotifications, {
    onSuccess: (data: { errors: string[]; failure_count: number; success_count: number }) => {
      UserNotification.success(
        `Added notification for ${data.success_count} rules. (${data.failure_count} rules failed to update.)`,
      );
      queryClient.invalidateQueries([sigmaRulesKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { bulkAddNotifications: mutateAsync, isAdding: isLoading };
}

export function useBulkDeleteSigmaRepos() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(bulkSigmaRepoDelete, {
    onSuccess: () => {
      UserNotification.success('Action completed');
      queryClient.invalidateQueries(['list-git-repos']);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { bulkDeleteSigmaRepos: mutateAsync, isMutating: isLoading };
}

export function useUploadSigmaRules() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(uploadSigmaRules, {
    onSuccess: (data: ImportAPIResponse) => {
      if (data.failure_count > 0) {
        const error =
          data.success_count > 0
            ? {
                type: 'warning',
                message: `${data.success_count} rules successfully imported, ${data.failure_count} rules failed to import.`,
              }
            : { type: 'error', message: `Failed to import all rules: ${data.errors}` };

        UserNotification[error.type](error.message);
      } else {
        UserNotification.success(`All ${data.success_count} rules imported successfully`);
      }

      queryClient.invalidateQueries([sigmaRulesKey]);
    },
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return { uploadSigmaRules: mutateAsync, uploadingRules: isLoading };
}

export function useValidateSigmaRuleZip() {
  const { mutateAsync, isLoading } = useMutation(validateSigmaRuleZip, {
    onError: (error: Error) => UserNotification.error(error.message),
  });

  return {
    validateSigmaRuleZip: mutateAsync,
    isValidating: isLoading,
  };
}
