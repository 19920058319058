import { useQuery } from '@tanstack/react-query';

import { DataLakeQuery } from '@graylog/enterprise-api';

import type { ActivePreviewSearch } from 'data-lake/preview/Types';

const useFetchActiveSearch = (): {
  data: ActivePreviewSearch | undefined;
  isFetching?: boolean;
  refetch: () => Promise<unknown>;
} => useQuery(['data-lake', 'preview', 'active-query'], () => DataLakeQuery.activeQuery());

export default useFetchActiveSearch;
