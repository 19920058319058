import __request__ from 'routing/request';
interface RetentionStrategyConfig {
    readonly max_number_of_indices: number;
    readonly type: string;
}
interface SimulateRuleRequest {
    readonly rule_source: RuleSource;
    readonly message: string;
}
interface RuleSource {
    readonly simulator_message: string;
    readonly rule_builder: RuleBuilder;
    readonly description: string;
    readonly created_at: string;
    readonly _scope: string;
    readonly id: string;
    readonly source: string;
    readonly title: string;
    readonly modified_at: string;
    readonly errors: ParseError[];
}
interface RuleBuilderStep {
    readonly step_title: string;
    readonly negate: boolean;
    readonly function: string;
    readonly id: string;
    readonly params: {
        readonly [_key: string]: Object;
    };
    readonly outputvariable: string;
    readonly conditions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
interface DataTieringConfig {
    readonly index_lifetime_min: string;
    readonly index_lifetime_max: string;
    readonly type: string;
}
interface Message {
    readonly processing_time: string;
    readonly source_input_id: string;
    readonly sequence_nr: number;
    readonly streams: Stream[];
    readonly fields_entries: unknown[];
    readonly field_names: string[];
    readonly is_source_inet_address: boolean;
    readonly receive_time: string;
    readonly message_id: string;
    readonly journal_offset: number;
    readonly source: string;
    readonly message: string;
    readonly inet_address: string;
    readonly message_queue_id: Object;
    readonly filter_out: boolean;
    readonly size: number;
    readonly index_sets: IndexSet[];
    readonly stream_ids: string[];
    readonly field_count: number;
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly complete: boolean;
    readonly timestamp: string;
}
interface BulkRuleRequest {
    readonly rules: string[];
}
interface StreamRule {
    readonly field: string;
    readonly stream_id: string;
    readonly description: string;
    readonly id: string;
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly inverted: boolean;
    readonly type: 'EXACT' | 'REGEX' | 'GREATER' | 'SMALLER' | 'PRESENCE' | 'CONTAINS' | 'ALWAYS_MATCH' | 'MATCH_INPUT';
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly content_pack: string;
    readonly value: string;
}
interface IndexSetConfig {
    readonly index_optimization_max_num_segments: number;
    readonly description: string;
    readonly index_optimization_disabled: boolean;
    readonly _scope: string;
    readonly field_type_profile: string;
    readonly title: string;
    readonly writable: boolean;
    readonly custom_field_mappings: CustomFieldMapping[];
    readonly index_analyzer: string;
    readonly rotation_strategy: RotationStrategyConfig;
    readonly index_template_type: string;
    readonly id: string;
    readonly regular: boolean;
    readonly index_match_pattern: string;
    readonly index_template_name: string;
    readonly replicas: number;
    readonly field_type_refresh_interval: string;
    readonly creation_date: string;
    readonly index_wildcard: string;
    readonly retention_strategy_class: string;
    readonly retention_strategy: RetentionStrategyConfig;
    readonly shards: number;
    readonly rotation_strategy_class: string;
    readonly index_prefix: string;
    readonly data_tiering: DataTieringConfig;
}
interface RotationStrategyConfig {
    readonly type: string;
}
interface PaginatedResponse_RuleSource {
    readonly [_key: string]: Object;
}
type anyArray = unknown[];
interface RuleMetricsConfigDto {
    readonly metrics_enabled: boolean;
}
interface CustomFieldMapping {
    readonly field: string;
    readonly type: string;
}
interface Output {
    readonly creator_user_id: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
}
interface ParseError {
    readonly line: number;
    readonly position_in_line: number;
    readonly type: string;
}
type RuleSourceArray = RuleSource[];
interface Stream {
    readonly outputs: Output[];
    readonly paused: boolean;
    readonly description: string;
    readonly title: string;
    readonly stream_rules: StreamRule[];
    readonly content_pack: string;
    readonly index_set_id: string;
    readonly index_set: IndexSet;
    readonly matching_type: 'AND' | 'OR';
    readonly remove_matches_from_default_stream: boolean;
    readonly disabled: boolean;
    readonly categories: string[];
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly default_stream: boolean;
}
interface RuleBuilder {
    readonly conditions: RuleBuilderStep[];
    readonly actions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
interface Validator {
}
interface IndexSet {
    readonly write_index_alias: string;
    readonly managed_indices: string[];
    readonly active_write_index: string;
    readonly all_index_aliases: {
        readonly [_key: string]: string[];
    };
    readonly up: boolean;
    readonly index_wildcard: string;
    readonly index_prefix: string;
    readonly newest_index: string;
    readonly config: IndexSetConfig;
}
/**
 * Create a processing rule from source
 */
export function createFromParser(rule: RuleSource): Promise<RuleSource> {
    return __request__('POST', '/system/pipelines/rule', rule, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all processing rules
 */
export function getAll(): Promise<RuleSourceArray> {
    return __request__('GET', '/system/pipelines/rule', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get rule metrics configuration
 */
export function metricsConfig(): Promise<RuleMetricsConfigDto> {
    return __request__('GET', '/system/pipelines/rule/config/metrics', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update rule metrics configuration
 */
export function updateMetricsConfig(): Promise<RuleMetricsConfigDto> {
    return __request__('PUT', '/system/pipelines/rule/config/metrics', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get function descriptors
 */
export function functionDescriptors(): Promise<anyArray> {
    return __request__('GET', '/system/pipelines/rule/functions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve the named processing rules in bulk
 * @param rules rules
 */
export function getBulk(rules?: BulkRuleRequest): Promise<RuleSourceArray> {
    return __request__('POST', '/system/pipelines/rule/multiple', rules, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of pipeline rules
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'title' | 'description' | 'id' = 'title', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | ' desc' = 'asc'): Promise<PaginatedResponse_RuleSource> {
    return __request__('GET', '/system/pipelines/rule/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Parse a processing rule without saving it
 */
export function parse(rule: RuleSource): Promise<RuleSource> {
    return __request__('POST', '/system/pipelines/rule/parse', rule, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get condition descriptors for ruleBuilder
 */
export function rulebuilderConditions(): Promise<anyArray> {
    return __request__('GET', '/system/pipelines/rule/rulebuilder/conditions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get function descriptors for rule builder
 */
export function rulebuilderFunctions(): Promise<anyArray> {
    return __request__('GET', '/system/pipelines/rule/rulebuilder/functions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Simulate a single processing rule
 */
export function simulate(request: SimulateRuleRequest): Promise<Message> {
    return __request__('POST', '/system/pipelines/rule/simulate', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a processing rule
 */
export function get(id?: string): Promise<RuleSource> {
    return __request__('GET', `/system/pipelines/rule/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Modify a processing rule
 */
export function update(rule: RuleSource, id?: string): Promise<RuleSource> {
    return __request__('PUT', `/system/pipelines/rule/${id}`, rule, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a processing rule
 */
export function remove(id?: string): Promise<void> {
    return __request__('DELETE', `/system/pipelines/rule/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
