import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface DataNodeDto {
    readonly data_node_status: 'UNCONFIGURED' | 'PREPARED' | 'STARTING' | 'AVAILABLE' | 'UNAVAILABLE' | 'REMOVING' | 'REMOVED';
    readonly is_master: boolean;
    readonly error_msg: string;
    readonly opensearch_roles: string[];
    readonly last_seen: string;
    readonly transport_address: string;
    readonly short_node_id: string;
    readonly object_id: string;
    readonly is_leader: boolean;
    readonly rest_api_address: string;
    readonly cert_valid_until: string;
    readonly version_compatible: boolean;
    readonly hostname: string;
    readonly datanode_version: string;
    readonly cluster_address: string;
    readonly id: string;
    readonly action_queue: 'REMOVE' | 'RESET' | 'STOP' | 'START' | 'REMOVED' | 'STOPPED' | 'STARTED' | 'CLEAR' | 'REQUEST_CSR' | 'REQUEST_CSR_WITH_AUTOSTART';
    readonly status: 'UNCONFIGURED' | 'PROVISIONED' | 'STARTING' | 'CONNECTED' | 'ERROR';
    readonly node_id: string;
}
interface EntityAttribute {
    readonly related_property: string;
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface PageListResponse_DataNodeDto {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: DataNodeDto[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
/**
 * Get a paginated list of all datanodes in this cluster
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function dataNodes(sort: 'title' | 'description' | 'type' = 'title', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PageListResponse_DataNodeDto> {
    return __request__('GET', '/system/cluster/datanodes', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
