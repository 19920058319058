import * as React from 'react';
import { useContext, useRef, useMemo } from 'react';
import * as Immutable from 'immutable';

import type CancellablePromise from 'logic/rest/CancellablePromise';
import type { After, OnLoadMessages, LogViewMessage, InfiniteScrollDirection } from 'logview/types';
import { isInfiniteScrollUp } from 'logview/helpers';

import { loadPrevPage, loadNextPage } from './LogViewDataFetching';
import ListStateContext from './ListStateContext';
import ScrollPositionContext from './ScrollPositionContext';
import type { LogViewState } from './LogViewStateProvider';

import type { PageRefs, TableRef } from '../LogViewWidget';

type InternalListState = LogViewState['listState'];

const _getVisiblePagesWithMessages = (
  visiblePagesIds: InternalListState['visiblePagesIds'],
  loadedPages: InternalListState['loadedPages'],
) => {
  let visiblePages = Immutable.OrderedSet<[number, Array<LogViewMessage>]>();

  visiblePagesIds
    .sort((pageIdA, pageIdB) => pageIdB - pageIdA)
    .forEach((pageId) => {
      visiblePages = visiblePages.add([pageId, loadedPages[pageId]]);
    });

  return visiblePages;
};

type Props = {
  children: React.ReactNode;
  pageRefs: PageRefs;
  after: After;
  total: number;
  tableRef: TableRef;
  listState: InternalListState;
  updateLogViewState: (logViewState: Omit<LogViewState, 'status'>) => void;
  onLoadMessages: OnLoadMessages;
  infiniteScrollDirection: InfiniteScrollDirection;
};

const ListStateProvider = ({
  children,
  pageRefs,
  after,
  total,
  listState,
  tableRef,
  updateLogViewState,
  onLoadMessages,
  infiniteScrollDirection,
}: Props) => {
  const {
    finishedScrollPositionUpdateRef,
    setFinishedScrollPositionUpdate,
    setLastPosition: setLastScrollPosition,
  } = useContext(ScrollPositionContext);
  const pages = _getVisiblePagesWithMessages(listState.visiblePagesIds, listState.loadedPages);
  const loadedAllPrevMessages = (total !== undefined && listState.loadedMessagesCount === total) || !after;
  const loadPrevPromiseRef = useRef<CancellablePromise<void>>(undefined);
  const _isInfiniteScrollUp = isInfiniteScrollUp(infiniteScrollDirection);

  const listStateContextValue = useMemo(() => {
    const updateListState = (
      newInternalListState: InternalListState,
      scrollPositionUpdate: LogViewState['scrollPositionUpdate'],
    ) => {
      setFinishedScrollPositionUpdate(false);
      setLastScrollPosition(tableRef.current.scrollTop);
      updateLogViewState({ listState: newInternalListState, scrollPositionUpdate });
    };

    const _loadPrevPage = () =>
      loadPrevPage({
        finishedScrollPositionUpdateRef,
        internalListState: listState,
        loadedAllPrevMessages,
        loadPrevPromiseRef,
        pageRefs,
        updateListState,
        onLoadMessages,
        infiniteScrollDirection,
      });

    const _loadNextPage = () =>
      loadNextPage({
        loadedAllPrevMessages,
        internalListState: listState,
        pageRefs,
        updateListState,
        finishedScrollPositionUpdateRef,
        infiniteScrollDirection,
      });

    const _cancelLoadPrevPage = () => {
      if (!!loadPrevPromiseRef.current && pages.size > 1) {
        loadPrevPromiseRef.current.cancel?.();
        // eslint-disable-next-line no-param-reassign
        loadPrevPromiseRef.current = undefined;
      }
    };

    return {
      actions: {
        cancelLoadPrevPage: _cancelLoadPrevPage,
        loadNextPage: _isInfiniteScrollUp ? _loadNextPage : _loadPrevPage,
        loadPrevPage: _isInfiniteScrollUp ? _loadPrevPage : _loadNextPage,
      },
      bottomPageId: listState.visiblePagesIds.min(),
      loadedAllPrevMessages,
      pages,
      allLoadedPages: listState.loadedPages,
    };
  }, [
    _isInfiniteScrollUp,
    listState,
    loadedAllPrevMessages,
    pages,
    setFinishedScrollPositionUpdate,
    setLastScrollPosition,
    tableRef,
    updateLogViewState,
    finishedScrollPositionUpdateRef,
    pageRefs,
    onLoadMessages,
    infiniteScrollDirection,
  ]);

  return <ListStateContext.Provider value={listStateContextValue}>{children}</ListStateContext.Provider>;
};

export default ListStateProvider;
