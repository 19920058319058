export const ICEBERG_QUERY = {
  'type': 'data_warehouse_iceberg',
  'query_string': '',
} as const;

export const PAGE_SIZE = 100;

export const WIDGET_DEFAULTS = {
  fields: ['timestamp', 'source', 'message', 'streams'],
  sort: 'DESC' as const,
  tieBreaker: 'timestamp',
  fieldFilters: [],
  fieldsOperator: 'AND' as const,
  size: PAGE_SIZE,
  timerange: { type: 'relative' as const, from: 1800 },
};
