import __request__ from 'routing/request';
interface PaginatedResponse_AssetResponse {
    readonly [_key: string]: Object;
}
interface Vulnerability {
    readonly severity: 'CRITICAL' | 'HIGH' | 'MEDIUM' | 'LOW' | 'INFO' | 'NONE';
    readonly cve: string;
    readonly vulnerability_id: string;
    readonly cvss: number;
}
interface AssetResponse {
    readonly risk_score_normalized: number;
    readonly rev: number;
    readonly risk_score: number;
    readonly imported_from: string;
    readonly created_at: string;
    readonly dn: string;
    readonly rev_date: string;
    readonly priority: number;
    readonly name: string;
    readonly vulnerabilities: AssetVulnerabilities[];
    readonly details: AssetDetails;
    readonly id: string;
    readonly category: string[];
}
interface FilterOptionsRequest {
    readonly fields: string[];
}
interface BulkCategoryRequest {
    readonly asset_ids: string[];
    readonly categories: string[];
}
interface PortInfo {
    readonly protocol: string;
    readonly port: string;
}
interface AssetDto {
    readonly risk_score_normalized: number;
    readonly rev: number;
    readonly source_asset_id: string;
    readonly risk_score: number;
    readonly imported_from: string;
    readonly event_definition_ids: string[];
    readonly created_at: string;
    readonly rev_date: string;
    readonly dn: string;
    readonly priority: number;
    readonly orig_id: string;
    readonly vulnerability_factor: number;
    readonly name: string;
    readonly highest_risk_score: number;
    readonly vulnerabilities: AssetVulnerabilities[];
    readonly details: AssetDetails;
    readonly id: string;
    readonly category: string[];
}
interface BulkCategoryResponse {
    readonly errors: string[];
}
interface AssetVulnerabilities {
    readonly scanner_id: string;
    readonly scanner_type: string;
    readonly hostname: string;
    readonly os: string;
    readonly ip: string;
    readonly vulnerabilities: Vulnerability[];
    readonly scan_id: string;
    readonly scan_name: string;
    readonly ports: PortInfo[];
    readonly mac: string;
}
interface AssetDetails {
    readonly type: string;
}
interface AssetFilterOptions {
    readonly email_addresses: string[];
    readonly ip_addresses: string[];
    readonly user_ids: string[];
    readonly last_name: string[];
    readonly hostnames: string[];
    readonly usernames: string[];
    readonly id: string;
    readonly first_name: string[];
    readonly mac_addresses: string[];
}
interface PaginatedResponse_AssetIdAndName {
    readonly [_key: string]: Object;
}
/**
 * Get a paginated list of existing assets
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listAssets(sort: 'name' | 'description' = 'name', page: number = 1, per_page: number = 15, query?: string, filters?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AssetResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new asset
 */
export function create(JSONBody?: AssetDto): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add categories to multiple assets
 */
export function addEvidence(JSONBody?: BulkCategoryRequest): Promise<BulkCategoryResponse> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/assets/bulk/categories_add', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all possible values for a list of fields
 */
export function getFilterOptions(JSONBody?: FilterOptionsRequest): Promise<AssetFilterOptions> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets/filter_options', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of asset IDs and names
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listAssetIdsAndNames(sort: 'name' = 'name', page: number = 1, per_page: number = 15, query?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AssetIdAndName> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets/names', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a detailed view of an asset by ID
 */
export function getAssetById(id: string): Promise<AssetResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Edit an existing asset
 */
export function editAsset(id: string, JSONBody?: AssetDto): Promise<void> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/assets/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing asset
 */
export function deleteAsset(id: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/assets/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
