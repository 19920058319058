import React, { useMemo, useState, useCallback, useRef } from 'react';
import { styled, css } from 'styled-components';
import * as Immutable from 'immutable';
import type { FormikProps } from 'formik';

import { DataLakeQuery } from '@graylog/enterprise-api';

import type { FormValues } from 'data-lake/preview/SearchBar';
import SearchBar from 'data-lake/preview/SearchBar';
import SearchFeatureExplanation from 'data-lake/preview/SearchFeatureExplanation';
import DLLogViewWidget from 'data-lake/preview/DLLogViewWidget';
import DataLakeHighlightingRules from 'data-lake/preview/DataLakeHighlightingRules';
import { DocumentTitle, Spinner } from 'components/common';
import PageContentLayout from 'components/layout/PageContentLayout';
import useSearchConfiguration from 'hooks/useSearchConfiguration';
import Query from 'views/logic/queries/Query';
import { Alert, Row } from 'components/bootstrap';
import useFieldTypes from 'data-lake/hooks/useFieldTypes';
import DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import Sidebar from 'views/components/sidebar/Sidebar';
import type HighlightingRule from 'views/logic/views/formatting/highlighting/HighlightingRule';
import HighlightingRulesContext from 'views/components/contexts/HighlightingRulesContext';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import useUserDateTime from 'hooks/useUserDateTime';
import Search from 'views/logic/search/Search';
import generateId from 'logic/generateId';
import useActiveSearch from 'data-lake/preview/hooks/useActiveSearch';
import SearchPageAutoRefreshProvider from 'views/components/contexts/SearchPageAutoRefreshProvider';
import { execute } from 'views/logic/slices/searchExecutionSlice';
import useAppDispatch from 'stores/useAppDispatch';
import DataLakePreviewStoreProvider from 'data-lake/preview/DataLakePreviewStoreProvider';
import createSearch from 'views/logic/slices/createSearch';
import { normalizeFromSearchBarForBackend } from 'views/logic/queries/NormalizeTimeRange';
import useIsLoading from 'views/hooks/useIsLoading';
import type { DataLakeSearchType, ActivePreviewSearch } from 'data-lake/preview/Types';
import { ICEBERG_QUERY } from 'data-lake/preview/Constants';
import useUrlParams from 'data-lake/preview/hooks/useUrlParams';
import useInitialSearchBarFormValues from 'data-lake/preview/hooks/useInitialFormValues';
import useWidgetResults from 'data-lake/preview/hooks/useWidgetResults';
import type DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import useDataLakeConfig from 'data-lake/configurations/hooks/useDataLakeConfig';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';
import Panel from 'components/bootstrap/Panel';
import { Link } from 'components/common/router';
import DataLakeRoutes from 'data-lake/Routes';
import PreviewIntro from 'data-lake/preview/PreviewIntro';
import ExpiredSearchInfo from 'data-lake/preview/ExpiredSearchInfo';

import createInitialWidget, { defaultWidget } from './createInitialWidget';

const StyledPageContentLayout = styled(PageContentLayout)`
  .page-content-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`;

const GridContainer = styled.div`
  display: flex;
  height: 100%;
`;

const FullHeightRow = styled(Row)(
  ({ theme }) => css`
    flex: 1;
    overflow: auto;

    > *:not(:last-child) {
      margin-bottom: ${theme.spacings.sm};
    }
  `,
);

const StyledAlert = styled(Alert)`
  margin: 0;
`;

type Props = {
  initialSearch: Search | undefined;
  initialUrlParams: FormValues;
  resetUrlQueryParams: () => void;
  initialSearchJobStatus: ActivePreviewSearch['status'];
};

const DataLakePreview = ({ initialSearch, initialUrlParams, resetUrlQueryParams, initialSearchJobStatus }: Props) => {
  const dispatch = useAppDispatch();
  const { userTimezone } = useUserDateTime();
  const isExecutingSearch = useIsLoading();
  const searchBarFormRef = useRef<FormikProps<FormValues>>(null);

  const [executedSearch, setExecutedSearch] = useState<Search | undefined>(
    initialSearchJobStatus === 'DONE' || initialSearchJobStatus === 'RUNNING' ? initialSearch : undefined,
  );
  const [widget, setWidget] = useState<DataLakeWidget>(createInitialWidget(initialSearch));
  const [highlightingRules, setHighlightingRules] = useState<Array<HighlightingRule>>([]);
  const [editing, setEditing] = useState(false);
  const initialFormValues = useInitialSearchBarFormValues(executedSearch, initialUrlParams);

  const { widgetData, errors } = useWidgetResults(executedSearch);

  const onChangeWidget = useCallback((newWidget: DataLakeWidget) => {
    setWidget(newWidget);

    return Promise.resolve();
  }, []);

  const toggleEdit = useCallback(() => setEditing((cur) => !cur), []);

  const onReset = useCallback(() => {
    setExecutedSearch(undefined);
    setWidget(defaultWidget);
    resetUrlQueryParams();
    DataLakeQuery.resetActiveQuery();
  }, [resetUrlQueryParams]);

  const highlightSidebarSection = useCallback(
    () => <DataLakeHighlightingRules setHighlightingRules={setHighlightingRules} />,
    [],
  );

  const onSearchBarSubmit = useCallback(
    async ({ timerange, stream, fields }: FormValues) => {
      resetUrlQueryParams();

      const updatedWidgetConfig: DataLakeWidgetConfig = widget.config
        .toBuilder()
        .fieldFilters(fields?.fieldFilters ? Immutable.List(fields.fieldFilters) : widget.config.fieldFilters)
        .filtersOperator(fields?.operator ?? widget.config.filtersOperator)
        .build();

      const updatedWidget = widget
        .toBuilder()
        .config(updatedWidgetConfig)
        .timerange(timerange ? normalizeFromSearchBarForBackend(timerange, userTimezone) : widget.timerange)
        .streams([stream])
        .build();

      const query = Query.builder()
        .id(generateId())
        .query(ICEBERG_QUERY)
        .searchTypes([
          {
            size: 100,
            filters_operator: updatedWidget.config.filtersOperator,
            timerange: updatedWidget.timerange,
            streams: [stream],
            field_filters: updatedWidget.config.fieldFilters.toArray(),
            fields: updatedWidget.config.fields.toArray(),
            type: DataLakeWidget.type,
            filters: [],
            sort: updatedWidget.config.sort,
          } as unknown as DataLakeSearchType,
        ])
        .build();

      const search = await createSearch(Search.create().toBuilder().queries([query]).build());

      return dispatch(execute(search, search.queries.first().id)).then(() => {
        setWidget(updatedWidget);
        setExecutedSearch(search);
      });
    },
    [dispatch, resetUrlQueryParams, userTimezone, widget],
  );

  const messages = useMemo(
    () =>
      widgetData?.messages?.map((root) => ({
        ...root,
        message: { ...root.message, _id: root.message.id },
      })),
    [widgetData],
  );

  return (
    <HighlightingRulesContext.Provider value={highlightingRules}>
      <GridContainer>
        <Sidebar
          sections={[
            {
              key: 'highlighting',
              icon: 'format_paragraph' as const,
              title: 'Highlighting',
              content: highlightSidebarSection,
            },
          ]}
          enableSidebarPinning={false}
          actions={[]}
          title="Data Lake Preview"
        />
        <StyledPageContentLayout>
          <SearchPageAutoRefreshProvider>
            <SearchFeatureExplanation />
            {initialSearchJobStatus === 'EXPIRED' && !executedSearch && !isExecutingSearch && (
              <ExpiredSearchInfo expiredSearch={initialSearch} searchBarFormRef={searchBarFormRef} />
            )}
            <SearchBar
              onReset={onReset}
              initialValues={initialFormValues}
              onSubmit={onSearchBarSubmit}
              formRef={searchBarFormRef}
            />
            <FullHeightRow>
              {!executedSearch && !isExecutingSearch && <PreviewIntro />}
              {executedSearch && (
                <>
                  {isExecutingSearch && <Spinner />}
                  {!isExecutingSearch && (
                    <>
                      {errors.length > 0 && (
                        <StyledAlert bsStyle="danger">{errors.map((error) => error.description)}</StyledAlert>
                      )}
                      {errors.length === 0 && (
                        <>
                          {messages?.length > 0 ? (
                            <DLLogViewWidget
                              messages={messages}
                              isFetching={false}
                              onToggleEdit={toggleEdit}
                              onChangeWidget={onChangeWidget}
                              widget={widget}
                              editing={editing}
                            />
                          ) : (
                            <StyledAlert>No messages have been found for selected filters.</StyledAlert>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </FullHeightRow>
          </SearchPageAutoRefreshProvider>
        </StyledPageContentLayout>
      </GridContainer>
    </HighlightingRulesContext.Provider>
  );
};

const DataLakePreviewPageComponent = () => {
  const { initialUrlParams, resetUrlQueryParams } = useUrlParams();
  const { config } = useSearchConfiguration();
  const { isInitialLoading: isLoadingFields, data: fieldTypes } = useFieldTypes();
  const {
    search: activeSearch,
    result: activeSearchResult,
    searchJobStatus: activeSearchJobStatus,
    isLoading: isLoadingActiveSearch,
  } = useActiveSearch(initialUrlParams);
  const fieldTypesContextValue = useMemo(
    () => ({
      all: Immutable.List(fieldTypes) ?? Immutable.List(),
      currentQuery: Immutable.List<FieldTypeMapping>(),
    }),
    [fieldTypes],
  );

  return (
    <DocumentTitle title="Data Lake Preview">
      {config && !isLoadingFields && !isLoadingActiveSearch ? (
        <DataLakePreviewStoreProvider result={activeSearchResult}>
          <FieldTypesContext.Provider value={fieldTypesContextValue}>
            <DataLakePreview
              initialSearch={activeSearch}
              initialSearchJobStatus={activeSearchJobStatus}
              initialUrlParams={initialUrlParams}
              resetUrlQueryParams={resetUrlQueryParams}
            />
          </FieldTypesContext.Provider>
        </DataLakePreviewStoreProvider>
      ) : (
        <Spinner />
      )}
    </DocumentTitle>
  );
};

const DataLakePreviewPagePlug = () => {
  const currentUser = useCurrentUser();
  const hasConfigPermissions = isPermitted(currentUser.permissions, 'data_warehouse_config:update');

  return (
    <PageContentLayout>
      <Panel bsStyle="info">
        <Panel.Heading>There is no active Data Lake Backend</Panel.Heading>
        <Panel.Body>
          To be able open the <b>Data Lake Preview Page</b> you need to configure and activate at least one{' '}
          {hasConfigPermissions ? (
            <Link to={DataLakeRoutes.unqualified.BACKEND}>Data Lake Backend</Link>
          ) : (
            <>
              Data Lake Backend. <span>Please contact your Admin to do all required configurations.</span>
            </>
          )}
        </Panel.Body>
      </Panel>
    </PageContentLayout>
  );
};
const DataLakePreviewPage = () => {
  const { data: config, isInitialLoading } = useDataLakeConfig();

  if (isInitialLoading) return <Spinner />;

  if (config?.active_backend) return <DataLakePreviewPageComponent />;

  return <DataLakePreviewPagePlug />;
};
export default DataLakePreviewPage;
