import type Search from 'views/logic/search/Search';
import DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import type { SearchTypeResult } from 'views/types';
import type { DataLakeLogsResult } from 'data-lake/preview/Types';
import useSearchResult from 'data-lake/preview/hooks/useSearchResult';

const isDataLakeResults = (searchTypeResult: SearchTypeResult): searchTypeResult is DataLakeLogsResult =>
  searchTypeResult.type === DataLakeWidget.type;

const useWidgetResults = (search: Search) => {
  const { result } = useSearchResult() ?? {};

  const activeQuery = search?.queries.first();
  const activeQueryId = activeQuery?.id;

  const errors = result?.errors ?? [];
  const searchTypeResults = result?.results?.[activeQueryId]?.searchTypes[activeQuery.searchTypes[0].id];

  if (!search || !activeQueryId || !searchTypeResults || !isDataLakeResults(searchTypeResults)) {
    return { widgetData: undefined, errors };
  }

  return {
    widgetData: searchTypeResults,
    errors,
  };
};

export default useWidgetResults;
