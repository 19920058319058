import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import type { StepType } from 'components/common/Wizard';
import { INPUT_WIZARD_STEPS, INPUT_WIZARD_FLOWS, OPEN_FLOW_STEPS } from 'components/inputs/InputSetupWizard';
import type { InputSetupWizardStep } from 'components/inputs/InputSetupWizard';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';
import { Wizard as CommonWizard } from 'components/common';
import useInputSetupWizard from 'components/inputs/InputSetupWizard/hooks/useInputSetupWizard';
import { useGetInstalledBundles } from 'illuminate/hooks/useBundlesAPI';

import { InstallIlluminateStep, SelectIlluminateStep } from './steps';

type Props = {
  openSteps: { [key in InputSetupWizardStep]?: StepType };
};

const Wizard = ({ openSteps }: Props) => {
  const { orderedSteps, setOrderedSteps, activeStep, setActiveStep, setWizardData, wizardData } = useInputSetupWizard();
  const {
    data: { valid: isValidIlluminateLicense },
    isInitialLoading: isLoadingIlluminateLicense,
  } = useLicenseValidityForSubject(LICENSE_SUBJECTS.illuminate);
  const { bundles, loadingBundles } = useGetInstalledBundles();

  const steps = useMemo(
    () => ({
      [INPUT_WIZARD_STEPS.INSTALL_ILLUMINATE]: {
        key: INPUT_WIZARD_STEPS.INSTALL_ILLUMINATE,
        title: <>Install Illuminate</>,
        component: <InstallIlluminateStep />,
        disabled: true,
      },
      [INPUT_WIZARD_STEPS.SELECT_ILLUMINATE]: {
        key: INPUT_WIZARD_STEPS.SELECT_ILLUMINATE,
        title: <>Illuminate</>,
        component: <SelectIlluminateStep />,
        disabled: true,
      },
    }),
    [],
  );

  const determineSteps = useCallback(() => {
    if (isLoadingIlluminateLicense || loadingBundles) return;
    const isIlluminateInstalled = bundles?.length > 0;

    if (isValidIlluminateLicense) {
      if (isIlluminateInstalled) {
        setOrderedSteps([INPUT_WIZARD_STEPS.SELECT_ILLUMINATE, ...OPEN_FLOW_STEPS[INPUT_WIZARD_FLOWS.ILLUMINATE]]);
        setActiveStep(INPUT_WIZARD_STEPS.SELECT_ILLUMINATE);
        setWizardData({ ...wizardData, flow: INPUT_WIZARD_FLOWS.ILLUMINATE });
      } else {
        setOrderedSteps([INPUT_WIZARD_STEPS.INSTALL_ILLUMINATE, ...OPEN_FLOW_STEPS[INPUT_WIZARD_FLOWS.NON_ILLUMINATE]]);
        setActiveStep(INPUT_WIZARD_STEPS.INSTALL_ILLUMINATE);
        setWizardData({ ...wizardData, flow: INPUT_WIZARD_FLOWS.ILLUMINATE });
      }
    } else {
      setOrderedSteps(OPEN_FLOW_STEPS[INPUT_WIZARD_FLOWS.NON_ILLUMINATE]);
      setActiveStep(OPEN_FLOW_STEPS[INPUT_WIZARD_FLOWS.NON_ILLUMINATE][0]);
      setWizardData({ ...wizardData, flow: INPUT_WIZARD_FLOWS.NON_ILLUMINATE });
    }
  }, [
    isLoadingIlluminateLicense,
    isValidIlluminateLicense,
    setOrderedSteps,
    setActiveStep,
    bundles,
    loadingBundles,
    setWizardData,
    wizardData,
  ]);

  useEffect(() => {
    if (!activeStep) {
      determineSteps();
    }
  }, [activeStep, determineSteps]);

  const combinedSteps = { ...openSteps, ...steps };

  if (loadingBundles || isLoadingIlluminateLicense || !orderedSteps || orderedSteps?.length < 1) return null;

  return (
    <CommonWizard
      activeStep={activeStep}
      hidePreviousNextButtons
      horizontal
      justified
      onStepChange={setActiveStep}
      steps={[...orderedSteps.map((step) => combinedSteps[step])]}
    />
  );
};

export default Wizard;
