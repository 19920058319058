import * as React from 'react';
import { useCallback } from 'react';
import { FieldArray, Field, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';

import { useGetAssetLabels } from 'security-app/hooks/useAssetsAPI';
import { Button, Input } from 'components/bootstrap';
import { IconButton, Spinner } from 'components/common';
import type { FieldFilters } from 'data-lake/Types';
import useFieldTypes from 'data-lake/hooks/useFieldTypes';
import FieldSelect from 'data-lake/components/FieldSelect';

import DataLakeFilterValueField from './DataLakeFilterValueField';

const FieldContainer = styled.div`
  flex-basis: 100%;
`;
const RemoveContainer = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
`;

const List = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xs};
    margin-bottom: ${theme.spacings.xs};
  `,
);
const FilterGroup = styled.div(
  ({ theme }) => css`
    flex-grow: 1;
    display: flex;
    gap: ${theme.spacings.xxs};
  `,
);
const Item = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.xxs};
  `,
);

type Props = {
  maxFilters?: number;
};

const FieldFiltersConfiguration = ({ maxFilters = undefined }: Props) => {
  const {
    values: { fieldFilters: filters },
    setFieldValue,
  } = useFormikContext<{ fieldFilters: Array<FieldFilters> }>();
  const { data: filterableFields, isInitialLoading: isLoadingFilterableField } = useFieldTypes();
  const { assetLabels, initialLoadingAssets: isLoadingAssets } = useGetAssetLabels({ page: 1, perPage: 0 });
  const isEmpty = (filters ?? []).length === 0;

  const handleRemove = useCallback(
    (index: number) => {
      setFieldValue(
        'fieldFilters',
        filters.filter((_, i) => i !== index),
      );
    },
    [filters, setFieldValue],
  );

  return (
    <FieldArray
      name="fieldFilters"
      validateOnChange={false}
      render={({ push }) => (
        <>
          {isLoadingFilterableField && <Spinner />}
          {!isEmpty && !isLoadingFilterableField && !isLoadingAssets && (
            <List>
              {filters.map((filter, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <Item key={`filter-field-${index}`}>
                  <FilterGroup>
                    <Field name={`fieldFilters.${index}.field_name`} required>
                      {({ field: { name, value }, meta: { error } }) => (
                        <FieldContainer>
                          <Input error={error} formGroupClassName="" id={`field_name-${index}`}>
                            <FieldSelect
                              value={value}
                              id={name}
                              name={name}
                              size="normal"
                              onChange={(newVal) => {
                                setFieldValue(`fieldFilters.${index}.field_name`, newVal);
                              }}
                              placeholder="Field name"
                            />
                          </Input>
                        </FieldContainer>
                      )}
                    </Field>
                    <DataLakeFilterValueField
                      fieldName={`fieldFilters.${index}.value`}
                      field={filterableFields.find((field) => field.name === filter.field_name)}
                      assetLabels={assetLabels}
                    />
                    <RemoveContainer>
                      {filters.length > 0 && (
                        <IconButton name="delete" onClick={() => handleRemove(index)} title="Remove field" />
                      )}
                    </RemoveContainer>
                  </FilterGroup>
                </Item>
              ))}
            </List>
          )}
          <Button
            disabled={maxFilters && filters?.length >= maxFilters}
            bsSize="xs"
            onClick={() => push({ field_name: '', value: '' })}
            name="plus"
            title="Add field filter">
            Add field
          </Button>
        </>
      )}
    />
  );
};

export default FieldFiltersConfiguration;
