import { qualifyUrl } from 'util/URLUtils';
import ApiRouter from 'routing/ApiRoutes';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import type { BulkActions } from 'security-app/types';
import fetch from 'logic/rest/FetchProvider';
import { stringifyFilters } from 'security-app/components/common/Filters/stringifyFilters';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import type {
  FileFormType,
  BodyPayload,
} from 'security-app/components/SigmaRules/ImportRulesModal/UploadRulesModal/types';

import type {
  SigmaRulesAPIResponseType,
  SigmaReposAPIResponseType,
  GitRepoContent,
  GitRepoPayload,
  ImportGitRuleType,
  ManualRulePayloadType,
  NotificationsResponseType,
  SigmaRuleZipValidationAPIResponse,
  ImportAPIResponse,
} from './sigmaAPI.types';

export const fetchSigmaRules = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = 'parsed_rule.title',
  direction: string = 'asc',
  filters: ColumnFilterData = {},
  userTimezone: string = null,
): Promise<SigmaRulesAPIResponseType> => {
  const params = [`page=${page}`, `per_page=${perPage}`, `sort=${orderBy}`, `direction=${direction}`];
  if (query) params.push(`query=${query}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`filters=${encodeURI(stringifyFilters(filters))}`);
    params.push(`user_timezone=${encodeURI(userTimezone)}`);
  }

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.allSigmaRules(params.join('&')).url));
};

export const fetchSigmaRulesFilterOptions = async ({ fields }: { fields: string[] }) => {
  const payload = {
    fields,
  };

  return fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.sigmaFilters().url), payload);
};

export const fetchStreams = async (): Promise<{
  total: number;
  streams: { id: string; title: string; is_editable: boolean; categories: string[] }[];
}> => fetch('GET', qualifyUrl(ApiRouter.StreamsApiController.index().url));

export const fetchNotifications = async (): Promise<NotificationsResponseType> =>
  fetch('GET', qualifyUrl('events/notifications'));

export const createSigmaRule = async (payload: ManualRulePayloadType) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.createSigmaRule().url), payload);

export const updateSigmaRule = async (payload: ManualRulePayloadType) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.oneSigmaRule(payload.id).url), payload);

export const getSigmaRule = async (ruleId: string) =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.oneSigmaRule(ruleId).url));

export const validateSigmaRule = async (ruleSource: string) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.validateSigmaRule().url), ruleSource);

type toggleRuleArgsType = {
  ruleId: string;
  enable: boolean;
};

export const toggleSigmaRule = async ({ ruleId, enable }: toggleRuleArgsType) => {
  const action = enable ? 'enable' : 'disable';

  return fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.toggleSigmaRule(ruleId, action).url));
};

export const deleteSigmaRule = async ({ ruleId }: { ruleId: string }) =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.oneSigmaRule(ruleId).url));

type gitRepoUpsertArgs = {
  repoId?: string;
  repo: GitRepoPayload;
};

export const addGitRepo = async ({ repo }: gitRepoUpsertArgs): Promise<unknown> =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.sigmaGitRepos().url), repo);

export const addDefaultRepo = async (): Promise<unknown> =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.defaultSigmaRepo().url));

export const updateGitRepo = async ({ repoId, repo }: gitRepoUpsertArgs): Promise<unknown> =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.sigmaGitRepo(repoId).url), repo);

export const deleteSigmaRepo = async ({ repoId }: { repoId: string }) =>
  fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.sigmaGitRepo(repoId).url));

export const listRepoContent = async (
  repoId: string,
  path: string = '',
  query: string = '',
): Promise<GitRepoContent[]> =>
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.sigmaGitRepo(repoId, path, query).url));

export const importRulesFromRepo = async ({ payload }: { payload: ImportGitRuleType[] }) =>
  fetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.importRepoSigmaRule().url), { rules: payload });

export const importAllRulesFromRepo = async (payload: ImportGitRuleType) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.importAllRules().url), payload);

export const refreshRepoRules = async ({ repoId }: { repoId: string }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.refreshRepoRules(repoId).url));

export const bulkSigmaRule = async ({ ids, action }: { ids: string[]; action: BulkActions }) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.sigmaBulkOperation(action).url), { ids });

export const bulkAddNotifications = async ({
  ids,
  action,
  notifications,
}: {
  ids: string[];
  action: BulkActions;
  notifications: string[];
}) => fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.sigmaBulkOperation(action).url), { ids, notifications });

export const bulkSigmaRepoDelete = async (ids: string[]) =>
  fetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.bulkDeleteSigmaRepos().url), { ids });

export const listGitRepos = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = 'name',
  direction: string = 'asc',
): Promise<SigmaReposAPIResponseType> => {
  const options = [`page=${page}`, `per_page=${perPage}`];
  if (query) options.push(`query=${query}`);
  if (orderBy) options.push(`sort=${orderBy}`);
  if (direction) options.push(`direction=${direction}`);

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.allSigmaRepos(options.join('&')).url));
};

export const uploadSigmaRules = async ({
  fileForms,
  body,
  progressCB,
}: {
  fileForms: FileFormType[];
  body: BodyPayload;
  progressCB: (percent: number) => void;
}) => {
  const formData = new FormData();
  const http = new XMLHttpRequest();

  fileForms.forEach((fileForm: FileFormType) => {
    const { id, file } = fileForm;

    formData.append('files', new Blob([file], { type: file.type }), id);
  });

  formData.append('body', new Blob([JSON.stringify(body)], { type: 'application/json' }));

  return new Promise<ImportAPIResponse>((resolve, reject) => {
    http.upload.onprogress = ({ loaded, total }) => {
      const percent = Math.ceil((loaded * 100) / total);
      progressCB(percent);
    };

    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200) {
          resolve(JSON.parse(http.response));
        } else {
          const errorMessage = JSON.parse(http.responseText)?.message ?? http.statusText;
          reject(new Error(errorMessage));
        }
      }
    };

    http.open('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.uploadSigmaRules().url), true);

    http.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    http.setRequestHeader('X-Requested-By', 'XMLHttpRequest');
    http.setRequestHeader('Access-Control-Allow-Origin', '*');

    http.send(formData);
  });
};

export const validateSigmaRuleZip = async (file: File) => {
  const formData = new FormData();
  const http = new XMLHttpRequest();

  formData.append('file', file);

  return new Promise<SigmaRuleZipValidationAPIResponse>((resolve, reject) => {
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200) {
          resolve(JSON.parse(http.response));
        } else {
          const errorMessage = JSON.parse(http.responseText)?.message ?? http.statusText;
          reject(new Error(errorMessage));
        }
      }
    };

    http.open('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.validateSigmaRuleZip().url), true);

    http.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    http.setRequestHeader('X-Requested-By', 'XMLHttpRequest');
    http.setRequestHeader('Access-Control-Allow-Origin', '*');

    http.send(formData);
  });
};
