import __request__ from 'routing/request';
interface ThreatChainSummary {
    readonly part_of_threat_chain: boolean;
    readonly rules: Rule[];
    readonly id: string;
    readonly title: string;
}
interface Rule {
    readonly db_id: string;
    readonly id: string;
    readonly title: string;
    readonly seen: boolean;
}
/**
 * Get Threat Campaign details for an Event Definition.
 */
export function getSummary(eventDefinitionId: string): Promise<ThreatChainSummary> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/threat_campaigns/summary_for_event_definition/${eventDefinitionId}`, null, {}, {
        'Accept': ['application/json']
    });
}
