import type { EventDefinition, SearchFilter } from 'components/event-definitions/event-definitions-types';
import type { EventDefinitionAggregation } from 'hooks/useEventDefinition';

import type { InvestigationAPIType } from './investigationsAPI.types';
import type { EventAPIType } from './eventsAPI.types';

export type SecurityEventStatusesType = 'NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED';

export const LOCAL_STORAGE_ITEM = 'security-events-last-search';
export const TIME_UNITS = ['DAYS', 'HOURS', 'MINUTES', 'SECONDS'];

export type TimeUnits = 'DAYS' | 'HOURS' | 'MINUTES' | 'SECONDS';

export type ProviderType = {
  type: string;
  template: string;
  require_values: boolean;
};

export type FieldSpecType = {
  data_type: string;
  providers: ProviderType[];
};

export type FieldSpecMapType = {
  [key: string]: FieldSpecType;
};

export type NotificationConfigType = {
  type: string;
  [key: string]: any;
};

export type NotificationType = {
  id: string;
  title: string;
  description: string;
  config: NotificationConfigType;
};

export type EventDefinitionConfigType = {
  type: string;
  execute_every_ms?: number;
  search_within_ms?: number;
  event_limit?: number;
  sigma_rule_id?: string;
  streams?: string[];
  filter?: SearchFilter[];
};

export type EventDefinitionNotification = {
  notification_id: number | string;
};

export type EventDefinitionNotificationSettingsType = {
  backlog_size: number;
  grace_period_ms: number;
};

export type PaginatedListAPIType = {
  total: number;
  page: number;
  per_page: number;
  count: number;
  grand_total?: number;
};

export type SecurityEventAPIProperties = {
  id?: string;
  event_id: string;
  status: SecurityEventStatusesType;
  opened_at: string;
  closed_at: string;
  updated_at: string;
  owner: string;
  notes: string;
  associated_investigations: InvestigationAPIType[];
  recorded_metrics: string[];
  is_security_event: boolean;
};

export type SecurityEventAPIType = SecurityEventAPIProperties & {
  event: EventAPIType;
};

export type SecurityEventEntity = SecurityEventAPIProperties & EventAPIType;

export type PaginatedSecurityEventsAPIType = PaginatedListAPIType & {
  security_events: SecurityEventAPIType[];
};

export type PaginatedEventDefinitionsAPIType = {
  pagination: PaginatedListAPIType;
  elements: EventDefinition[];
};

export type NewSecurityEventPayloadType = {
  owner: string;
  event_id?: string;
  notes: string;
  status: SecurityEventStatusesType;
};

export type NewNotificationPayloadType = {
  description: string;
  id: string;
  title: string;
  config: NotificationConfigType;
};

export type OwnerOptionType = {
  id: string;
  name: string;
  type: string;
};

export type UserType = {
  id: string;
  full_name: string;
};

export type TeamType = {
  id: string;
  name: string;
};

export type EventDefinitionDataType = {
  eventDefinition: EventDefinition;
  aggregations: EventDefinitionAggregation[];
};

export type PaginatedNotificationsAPIType = {
  pagination: PaginatedListAPIType;
  elements: NotificationType[];
};

export const SECURITY_EVENT_STATUSES = {
  NEW: 'New',
  INVESTIGATING: 'Investigating',
  FALSE_POSITIVE: 'False Positive',
  CLOSED: 'Closed',
} as const;

export type Status = keyof typeof SECURITY_EVENT_STATUSES;
