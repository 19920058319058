import * as React from 'react';
import { useCallback, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import DataLakeActionModalForm from 'data-lake/components/DataLakeActionModalForm';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';
import { getDataLakeUriParams } from 'data-lake/logic/Utils';
import fetch from 'logic/rest/FetchProvider';
import { Button } from 'components/bootstrap';
import { Link } from 'components/common/router';
import useUserDateTime from 'hooks/useUserDateTime';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import { ConfirmDialog, Pluralize } from 'components/common';
import type { DataLakeActionValues } from 'data-lake/Types';
import DataLakeRoutes from 'data-lake/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import RetrievalHelp from 'data-lake/RetrievalHelp';

type Props = {
  streamIds: Array<string>;
  timeRange?: AbsoluteTimeRange;
};

const DataLakeSearchRetrieve = ({ streamIds, timeRange = null }: Props) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { userTimezone } = useUserDateTime();
  const currentUser = useCurrentUser();

  const onRetrieveSubmit = useCallback(
    (values: DataLakeActionValues) => {
      const uriParams = getDataLakeUriParams(values, userTimezone);
      const { stream_ids, inclusion_type, field_filters, operator } = values;

      fetch('POST', qualifyUrl(`${DATA_LAKE_API_ROUTES.DataLakeAPIController.bulk_retrieval().url}?${uriParams}`), {
        stream_ids,
        inclusion_type,
        field_filters,
        operator,
      })
        .then(() => {
          UserNotification.success('Data Lake retrieve jobs was successfully submitted.', 'Success');
          setShowInfoModal(true);
        })
        .catch((error) => {
          UserNotification.error(`An error occurred while submitting retrieval job. ${error}`);
        })
        .finally(() => {
          setShowRetrievalModal(false);
        });
    },
    [userTimezone],
  );

  const progressOverview = isPermitted(currentUser.permissions, 'data_warehouse_config:read') ? (
    <>
      click <Link to={DataLakeRoutes.ARCHIVE.LIST}>Data Lake overview page</Link>
    </>
  ) : (
    <>contact your administrator</>
  );

  return (
    <>
      <Button bsStyle="link" onClick={() => setShowRetrievalModal(true)}>
        From {streamIds.length} <Pluralize singular="stream" plural="streams" value={streamIds.length} />
      </Button>

      {showRetrievalModal && (
        <DataLakeActionModalForm
          modalTitle="Restore Data Lake data"
          defaultTimeRange={timeRange}
          type="retrieval"
          show={showRetrievalModal}
          onHide={() => setShowRetrievalModal(false)}
          submitButtonText="Retrieve"
          streamIds={streamIds}
          onSubmit={onRetrieveSubmit}>
          <p>You are about to retrieve {streamIds.length} streams.</p>
          <RetrievalHelp />
        </DataLakeActionModalForm>
      )}
      {showInfoModal && (
        <ConfirmDialog
          show
          onConfirm={() => setShowInfoModal(false)}
          hideCancelButton
          title="Data Lake retrieval"
          btnConfirmText="Ok">
          Retrieval jobs for the selected stream were submitted successfully. To check the progress {progressOverview}.
          Once the job is done the retrieved messages will be available on search results.
        </ConfirmDialog>
      )}
    </>
  );
};

export default DataLakeSearchRetrieve;
